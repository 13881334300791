import React, { Component } from 'react';
import axios from 'axios';
import { APIURL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR, PORT } from '../../../config/config';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CarBookForm from '../CarBookForm/CarBookForm';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import ThaiBaht from 'thai-baht-text';
import NumberFormat from 'react-number-format';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {AlertError, AlertSuccess, AlertWarning} from '../../Alert/Alert';
import PreviewImage from '../../../views/PreviewImage';
import { NumberUtil } from '../../../utils/number-util';
import parse from 'html-react-parser';
import { getDocTypeConfig } from '../../../services/companysetting';
import { Redirect } from 'react-router-dom';
import stringUtil from "../../../utils/stringUtil";
import { CarBookUtil } from '../../../utils/carBookUtil'
import {checkGroupPermission, getGroupPermission} from "../../../services/grouppermission";
import Swal from "sweetalert2";
// import {CarSellUtil} from "../../../utils/carSellUtil";
// import stringUtil from '../../utils/stringUtil';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
};

const port = Number(window.env.PORT);

class CarBook extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            lists: [],
            load_data: true,
            danger: false,
            refund:false,
            car: '',
            car_id:'',
            customer_id:'',
            customer: {},
            customer_select:false,
            toggle_bill_print: false,
            customer_list: [],
            car_sell_pay:[],
            car_book_list:[],
            car_book_id: '',
            car_sell_id:'',
            bank:[],
            car_status:[],
            sales_type:[],
            person_transaction:'',
            startDatePay: moment(),
            carbookprimary: false,
            car_sell_pay_id:0,
            car_sell_pay_count:0,
            car_book_refund: 0,
            car_sell_pay_selected: {},
            book_status: 'add',
          // add payment
          toggle_add_payment: false,
          date_pay: new Date(),
          car_sell_list_pay: [],
          car_sell_list_pay_id: 0,
          // sales_type: [],
          // bank: [],
          sales_type_id: 0,
          car_sell_pay_add: {},
          carBase64Img: '',
          width: 0,
          height: 0,
          toggle_slip: false,
          slip_image1: IMAGE_NOPHOTO_URL,
          slip_image2: IMAGE_NOPHOTO_URL,
          slip_key1: '',
          slip_key2: '',
          preview_image_url: '',
          preview_image_select: false,
          logoBase64Img: '',
          book_number: '',
          volume_number: '',
          car_license_plate: '',
          province_name : '',
          car_sell_pay_refund : 0,
          //
          company_setting: null,
          doc_type: '',
          doc_config: '',
          is_vat: 0,
          include_vat: 0,
          doc_no: '',
          btnClick: '',
          contractCheck: {},
          book_comment_refund: null,
          menuID: [701],
          grouppermissions: [],
          is_car_book_date_modal: false,
          startDateCarBook: new Date(),
       };

      this.saveBookNumber = this.saveBookNumber.bind(this);
      this.updateBookNumber = this.updateBookNumber.bind(this);
      this.handleDatePay = this.handleDatePay.bind(this);
      this.printOcpb = this.printOcpb.bind(this);
      this.toggleCarBookPrimary= this.toggleCarBookPrimary.bind(this);
      this.loadCarBook = this.loadCarBook.bind(this);
      this.togglePrintBill = this.togglePrintBill.bind(this);
      this.printCarBook = this.printCarBook.bind(this);
      this.printCarBookPdf = this.printCarBookPdf.bind(this);
      //
      this.handleDatePayAdd = this.handleDatePayAdd.bind(this);
      this.toggleAddPayment = this.toggleAddPayment.bind(this);
      this.handleCarSellPayAddSubmit = this.handleCarSellPayAddSubmit.bind(this);
      this.makeCarImgBase64 = this.makeCarImgBase64.bind(this);
      this.loadCarImageBase64 = this.loadCarImageBase64.bind(this);
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      // add slip
      this.toggleSlip = this.toggleSlip.bind(this);
      this.saveCarImage = this.saveCarImage.bind(this);
      this.handleUploadFile = this.handleUploadFile.bind(this);
      this.onPreviewSelected = this.onPreviewSelected.bind(this);
      this.togglePreviewImage = this.togglePreviewImage.bind(this);
      //
      this.loadCompanySetting = this.loadCompanySetting.bind(this);
      this.loadCompanyLogoBase64 = this.loadCompanyLogoBase64.bind(this);
      this.carBookCancel = this.carBookCancel.bind(this);
      this.getCarBookStatusText = this.getCarBookStatusText.bind(this);
      this.getCarBookManage = this.getCarBookManage.bind(this);
      this.printCarBookPdfCustomer = this.printCarBookPdfCustomer.bind(this);
      this.printCarBookPdfCustomerAtCarSale = this.printCarBookPdfCustomerAtCarSale.bind(this);
      this.handleOnClick = this.handleOnClick.bind(this);

      this.carBookCancelWithDeleteCustomer = this.carBookCancelWithDeleteCustomer.bind(this);
      this.loadGroupPermission = this.loadGroupPermission.bind(this);
      this.getManageCarBookDelete = this.getManageCarBookDelete.bind(this);
      this.getManageCarBookEdit = this.getManageCarBookEdit.bind(this);

      this.openCarBookDateModal = this.openCarBookDateModal.bind(this);
      this.toggleCarBookDateModal = this.toggleCarBookDateModal.bind(this);
      this.editCarBookDate = this.editCarBookDate.bind(this);
      this.handleCarBookDate = this.handleCarBookDate.bind(this);
  }

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID
    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  handleDatePay (date) {
    this.setState({
      startDatePay: new Date(date)
    })
  }

  handleDatePayAdd(date) {
    this.setState({
      date_pay: new Date(date)
    })
  }

  togglePrintBill(){
        this.setState({
            toggle_bill_print: !this.state.toggle_bill_print
        })
  }

  toggleSlip(){
    this.setState({
      toggle_slip: !this.state.toggle_slip
    })
  }

  saveCarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;
  }

  formatDate (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  printOcpb (car_sell_pay) {
    const car_book_id = car_sell_pay.car_book_id;

    let car_brance = ''
    let car_brance_address = ''
    let car_brance_tel = ''
    let tax_id = this.state.company_setting.juristic_number

    if (port === 9027) {
      // mktent ยกเลิกการใช้งานแล้ว
      // mktent ที่อยู่สาขาตาม user ที่จอง
      car_brance = car_sell_pay.branch_name
      car_brance_address = car_sell_pay.branch_address
      car_brance_tel = car_sell_pay.branch_tel

    } else if (Number(port) === 9008) {
      // ชื่อตาม company setting ทุกสาขา
      let company_name = this.state.company_setting.company_name
      car_brance = company_name
      car_brance_address = this.state.car.branch_address
      car_brance_tel = this.state.car.branch_tel
    } else {
      // ที่อยู่ตามรถ
      let company_name = this.state.company_setting.company_name
      car_brance_address = this.state.company_setting.company_address
      car_brance_tel = this.state.company_setting.company_tel
      //car_brance = this.state.car.branch_name
      car_brance = company_name
    }

    let car_brand_name = this.state.car.car_brand_name
    let car_model_name = this.state.car.car_model_name

    let car_name2 = car_brand_name + ' ' + car_model_name

    const car_name = this.state.car.car_name //ยี่ห้อรถ
    const car_color_name = this.state.car.car_color_name //สี
    const car_year_name = this.state.car.car_year_name //ปี
    // const car_license_plate_new = this.state.car.car_license_plate_new //ทะเบียนรถยนต์
    // const car_province_name_new = this.state.car.province_name_new//จังหวัด
    const car_license_plate = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
                              this.state.car.car_license_plate_new : this.state.car.car_license_plate_old

    const province_name = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
        this.state.car.province_name_new : this.state.car.province_name_old

    const customer_name = car_sell_pay.customer_name //ชื่อลูกค้า
    const customer_lastname = car_sell_pay.customer_lastname //นามสกุลลูกค้า
    const customer_address = car_sell_pay.customer_address //ที่อยู่ลูกค้า
    const customer_mobile = car_sell_pay.customer_mobile //
    const date_pay = moment(car_sell_pay.date_pay).locale('th').format('LL') //วันที่ออกใบเสร็จหรือวันที่จ่าย
    // const person_transaction = car_sell_pay.person_transaction //ผู้ออกใบเสร็จ
    // let person_transaction = ''
    let employee_book = car_sell_pay.employee_book_id === 0 || car_sell_pay.employee_book_id === '' ? 'ไม่ระบุ' : car_sell_pay.employee_book_name + ' ' + car_sell_pay.employee_book_lastname
    let person_transaction = car_sell_pay.booking_by === null || car_sell_pay.booking_by === '' ? employee_book : car_sell_pay.booking_by //ผู้รับจอง

    // if(Number(port) === 9008) {
    //    person_transaction = car_sell_pay.booking_by === null ? car_sell_pay.book_by : car_sell_pay.booking_by //ผู้รับจอง
    // }else {
    //    person_transaction = car_sell_pay.book_by //ผู้ออกใบเสร็จ
    // }
    const car_sell_list_pay = car_sell_pay.car_sell_list_pay_name //รายการที่จ่าย
    const sales_type = car_sell_pay.sales_type_name //รูปแบบการจ่าย

    const car_sell_pay_count = '฿' + car_sell_pay.car_sell_pay_count.toLocaleString()  //ยอดเงินจอง

    let bill_no = 'R'+car_book_id //this.refs.bill_no.value

    const book_number = car_sell_pay.book_number //
    const volume_number = car_sell_pay.volume_number === '' ? '-' : String(car_sell_pay.volume_number) //

    if (book_number !== '' && book_number !== null) {
      bill_no = book_number
    }

    // car_sell_pay.car_sell_pay_count>=3000? '***ลูกค้ารับแหวนทองแล้ว' : ' ';
    let car_note =  ' '

    let car_book_status_id = car_sell_pay.car_book_status_id

    let moneyText = ThaiBaht(car_sell_pay.car_sell_pay_count)

    let car_sell_pay_refund = '฿' + car_sell_pay.car_sell_pay_refund.toLocaleString()  //ยอดคืนเงินจอง

    let billDetail = car_sell_list_pay + '  ' + car_name + '  '
                    + 'สี' + car_color_name + '\n '
                    + 'ทะเบียนรถ ' +  car_license_plate + ' ' + 'จังหวัด' + province_name +' \n'
                    + 'การชำระเงิน  :  ' + sales_type + ' '
                    + car_note

    if(port === 9048){
      billDetail = car_sell_list_pay + '  ' + car_name2 + '  '
          + 'ปี ' + car_year_name + ' '
          + 'สี' + car_color_name + '\n '
          + 'ทะเบียนรถ ' +  car_license_plate + ' ' + 'จังหวัด' + province_name +' \n'
          + 'การชำระเงิน  :  ' + sales_type + ' '
          + car_note
    }

    let printRefund = ''
    if(port === 9040 && car_book_status_id === 2){
      printRefund = [
            {text: '1', style: 'myCellDetail'},
            {text: billDetail, style: 'body'},
            {text: car_sell_pay_refund, style: 'myCellPrice'},
            {text: car_sell_pay_refund, style: 'myCellSumPrice'}
            ]
    }else{
      printRefund = [
        {text: '1', style: 'myCellDetail'},
        {text: billDetail, style: 'body'},
        {text: car_sell_pay_count, style: 'myCellPrice'},
        {text: car_sell_pay_count, style: 'myCellSumPrice'}
      ]
    }

    let pay_amount = ''
    if(port === 9040 && car_book_status_id === 2){
      pay_amount = [{text: 'รวมราคา', style: 'myCellSumText'}, {
        text: car_sell_pay_refund,
        style: 'myCellSumCost'
      }]
    }else{
      pay_amount = [{text: 'รวมราคา', style: 'myCellSumText'}, {
        text: car_sell_pay_count,
        style: 'myCellSumCost'
      }]
    }

    let pay_amount_total = ''
    if(port === 9040 && car_book_status_id === 2){
      pay_amount_total = [{
        text: 'รวมราคาทั้งสิ้น   '  + moneyText,
        style: 'myCellSumTextCost'
      }, {text: car_sell_pay_refund, style: 'myCellSumTextCostNum'}]
    }else{
      pay_amount_total = [{
        text: 'รวมราคาทั้งสิ้น   '  + moneyText,
        style: 'myCellSumTextCost'
      }, {text: car_sell_pay_count, style: 'myCellSumTextCostNum'}]
    }

    let businessCol = []
    //let businessCol2 = []
    if (tax_id !== '0000000000000' && tax_id !== null) {
      // แสดงเลขที่ผู้เสียภาษี
      businessCol = {
        columns: [
          {
            width: '15%',
            text: 'ผู้ประกอบธุรกิจ :', style: 'bodycompany'
          },
          {
            width: '45%', style: 'bodycompany',
            text: car_brance ,
          },
          {
            width: '15%',
            text: 'เลขที่ผู้เสียภาษี :', style: 'bodycompany'
          },
          {
            width: '25%', style: 'bodycompany',
            text: tax_id,
          },
        ],
        columnGap: 20,
        lineHeight: 1
      }
    } else {
      // ไม่แสดงเลขที่ผู้เสียภาษี
      businessCol = {
        columns: [
          {
            width: '15%',
            text: 'ผู้ประกอบธุรกิจ :', style: 'bodycompany'
          },
          {
            width: '85%', style: 'bodycompany',
            text: car_brance,
          },
        ],
        columnGap: 20,
        lineHeight: 1
      }
    }


    let businessCol2 = JSON.parse(JSON.stringify(businessCol));
    let printRefundCopy = JSON.parse(JSON.stringify(printRefund));
    let payAmountCopy = JSON.parse(JSON.stringify(pay_amount));
    let payAmountTotal = JSON.parse(JSON.stringify(pay_amount_total));

    // const carDetail2 = JSON.parse(JSON.stringify(carDetail));
    let bookBG = CarBookUtil.getBookBG(port, car_book_status_id)


    let doc_no = car_sell_pay.doc_no
    let doc_config = this.state.doc_config
    let colBillNumber = null

    if (doc_no !== null && doc_config === 'auto') {

      colBillNumber = {
        columns: [
          {
            width: '20%',
            text: ' ', style: 'bodycompany'
          },
          {
            width: '40%', style: 'bodycompany',
            text: ' ',
          },
          {
            width: '15%',
            text: 'เลขที่ :', style: 'bodycompany'
          },
          {
            width: '25%', style: 'bodycompany',
            text: doc_no,
          },
        ],
        columnGap: 20,
        lineHeight: 1
      }
    } else if (doc_no === null && (volume_number === null || volume_number === '')) {
      colBillNumber = {
        columns: [
          {
            width: '20%',
            text: ' ', style: 'bodycompany'
          },
          {
            width: '40%', style: 'bodycompany',
            text: ' ',
          },
          {
            width: '20%',
            text: 'เลขที่ :', style: 'bodycompany'
          },
          {
            width: '20%', style: 'bodycompany',
            text: bill_no,
          },
        ],
        columnGap: 20,
        lineHeight: 1
      }
    } else {
      colBillNumber = {
        columns: [
          {
            width: '20%',
            text: ' ', style: 'bodycompany'
          },
          {
            width: '46%', style: 'bodycompany',
            text: ' ',
          },
          {
            width: '5%',
            text: 'เล่มที่ :', style: 'bodycompany'
          },
          {
            width: '6%', style: 'bodycompany',
            text: volume_number,
          },
          {
            width: '5%',
            text: 'เลขที่ :', style: 'bodycompany'
          },
          {
            width: '18%', style: 'bodycompany',
            text: bill_no,
          },
        ],
        columnGap: 20,
        lineHeight: 1
      }
    }

    let colBillNumberCopy = JSON.parse(JSON.stringify(colBillNumber))

    let emptyRow1 = [
      {
        columns: [
          {
            width: '100%',
            text: '.',
            style: 'textWhite', margin: [0, 3, 0, 0],
          },
        ],
        columnGap: 0,
        lineHeight:1,
      }
    ]
    let emptyRow2 = [
      {
        columns: [
          {
            width: '100%',
            text: '.',
            style: 'textWhite', margin: [0, 3, 0, 0],
          },
        ],
        columnGap: 0,
        lineHeight:1,
      },
    ]

    let emptyRow = null
     if (PORT === 9043) {
       // atcarsale
       emptyRow = billDetail.length <= 128 ? emptyRow2 : emptyRow1;
     }

    const docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [25, 25, 25, 0],
      background: {image: 'book', width: 600},
      /*watermark: { text: 'คืนเงินมัดจำแล้ว', angle: -360, fontSize: 24, color: 'red', bold: true,},*/
      content: [
        {text: 'ใบเสร็จรับเงิน', style: 'bodyhead', lineHeight: 1.0, margin: [0, 0, 0, 10]},
        colBillNumber,
        {
          columns: [
            {
              width: '20%',
              text: '', style: 'bodycompany'
            },
            {
              width: '40%', style: 'bodycompany',
              text: '',
            },
            {
              width: '15%',
              text: 'วันที่ออกใบเสร็จ :', style: 'bodycompany'
            },
            {
              width: '25%', style: 'bodycompany',
              text: date_pay,
            },
          ],
          columnGap: 20,
          lineHeight: 1
        },
        businessCol,
        {
          columns: [
            {
              width: '14%',
              text: 'สถานที่ตั้ง :', style: 'bodycompany'
            },
            {
              width: '86%', style: 'bodycompany',
              text: car_brance_address,
            },
          ],
          columnGap: 20,
          lineHeight: 1
        },
        {
          columns: [

            {
              width: '15%',
              text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
            },
            {
              width: '45%', style: 'bodycompany',
              text: car_brance_tel,
            },
            {
              width: '15%',
              text: 'ผู้รับจอง(เซลล์) :', style: 'bodycompany'
            },
            {
              width: '25%', style: 'bodycompany',
              text: person_transaction,
            },

          ],
            columnGap: 20,
            lineHeight: 1.3
        },

        {
          columns: [
            {
              width: '15%',
              text: 'ชื่อ-สกุลลูกค้า :', style: 'bodycompany'
            },
            {
              width: '45%', style: 'bodycompany',
              text: customer_name + '   ' + customer_lastname,
            },
            {
              width: '15%',
              text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
            },
            {
              width: '25%', style: 'bodycompany',
              text: customer_mobile,
            },
          ],
          columnGap: 20,
          lineHeight: 1.0
        },

        {
          columns: [
            {
              width: '14%',
              text: 'ที่อยู่ :', style: 'bodycompany'
            },
            {
              width: '86%', style: 'bodycompany',
              text: customer_address,
            },

          ],

          columnGap: 20,
          lineHeight: 1.2
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 2,
            widths: [30, '*', 70, 80],
            heights: [20, 20],

            body: [
              [{text: 'ลำดับ', style: 'myCell'},
                {text: 'รายการ', style: 'myCell'},
                {text: 'ราคา/หน่วย', style: 'myCell'},
                {text: 'รวมราคา', style: 'myCell'}],
              printRefund,
            ]
          },

          columnGap: 20,
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', 80],
            heights: [20],

            body: [
              pay_amount,
            ]
          },

          columnGap: 20,
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', 80],
            heights: [20],

            body: [
              pay_amount_total,
            ]
          },

          columnGap: 20,
          lineHeight: 1
        },

        {
          columns: [
            {
              width: '100%',
              text: 'หมายเหตุ : หากยกเลิกหรือติดแบล็คลิสต์ไม่คืนมัดจำ',
              style: 'textNote', margin: [0, 3, 0, 0],
            },
          ],
          columnGap: 0,
          lineHeight:1,
        },
        emptyRow,
        [

          {text: 'ใบเสร็จรับเงิน', style: 'bodyhead', lineHeight: 1.3,  margin: [0, 80, 0, 0]},
          colBillNumberCopy,
          /*{
            columns: [
              {
                width: '20%',
                text: '', style: 'bodycompany'
              },
              {
                width: '46%', style: 'bodycompany',
                text: '',
              },
              {
                width: '5%',
                text: 'เล่มที่', style: 'bodycompany'
              },
              {
                width: '6%', style: 'bodycompany',
                text: volume_number,
              },
              {
                width: '5%',
                text: 'เลขที่', style: 'bodycompany'
              },
              {
                width: '18%', style: 'bodycompany',
                text: bill_no,
              },
            ],

            columnGap: 20,
            lineHeight: 1
          },*/
          {
            columns: [
              {
                width: '20%',
                text: '', style: 'bodycompany'
              },
              {
                width: '40%', style: 'bodycompany',
                text: '',
              },
              {
                width: '15%',
                text: 'วันที่ออกใบเสร็จ :', style: 'bodycompany'
              },
              {
                width: '25%', style: 'bodycompany',
                text: date_pay,
              },

            ],
            columnGap: 20,
            lineHeight: 1
          },
          businessCol2,
          {
            columns: [
              {
                width: '14%',
                text: 'สถานที่ตั้ง :', style: 'bodycompany'
              },
              {
                width: '86%', style: 'bodycompany',
                text: car_brance_address,
              },

            ],
            columnGap: 20,
            lineHeight: 1
          },

          {
            columns: [

              {
                width: '15%',
                text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
              },
              {
                width: '45%', style: 'bodycompany',
                text: car_brance_tel,
              },
              {
                width: '15%',
                text: 'ผู้รับจอง(เซลล์) :', style: 'bodycompany'
              },
              {
                width: '25%', style: 'bodycompany',
                text: person_transaction,
              },

            ],

            columnGap: 20,
            lineHeight: 1.3
          },

          {
            columns: [
              {
                width: '15%',
                text: 'ชื่อ-สกุลลูกค้า :', style: 'bodycompany'
              },
              {
                width: '45%', style: 'bodycompany',
                text: customer_name + '   ' + customer_lastname,
              },
              {
                width: '15%',
                text: 'เบอร์โทรศัพท์ :', style: 'bodycompany'
              },
              {
                width: '25%', style: 'bodycompany',
                text: customer_mobile,
              },

            ],

            columnGap: 20,
            lineHeight: 1.0
          },

          {
            columns: [
              {
                width: '14%',
                text: 'ที่อยู่ :', style: 'bodycompany'
              },
              {
                width: '86%', style: 'bodycompany',
                text: customer_address,
              },

            ],

            columnGap: 20,
            lineHeight: 1.2
          },

          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 2,
              widths: [30, '*', 70, 80],
              heights: [20, 20],

              body: [
                [{text: 'ลำดับ', style: 'myCell'},
                  {text: 'รายการ', style: 'myCell'},
                  {text: 'ราคา/หน่วย', style: 'myCell'},
                  {text: 'รวมราคา', style: 'myCell'}],
                printRefundCopy,
              ]
            },

            columnGap: 20,
          },

          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: ['*', 80],
              heights: [20],

              body: [
                payAmountCopy,
              ]
            },

            columnGap: 20,
          },

          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: ['*', 80],
              heights: [20],
              body: [
                payAmountTotal,
              ]
            },

            columnGap: 20,
            lineHeight: 1
          },

          {
            columns: [
              {
                width: '100%',
                text: 'หมายเหตุ : หากยกเลิกหรือติดแบล็คลิสต์ไม่คืนมัดจำ',
                style: 'textNote', margin: [0, 3, 0, 0]
              },
            ],
            columnGap: 0,
            lineHeight:1,
          },

        ],

      ],

      images: {
        book: bookBG
      },

      styles: {
        bodyhead: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        body: {
          fontSize: 14,
        },
        bodycompany: {
          fontSize: 14,
          bold: true,
        },
        anotherStyle: {
          italic: true,
        },
        myCell: {
          fontSize: 14,
          fillColor: '#BEBEBE',
          alignment: 'center',
          bold: true,
        },
        myCellDetail: {
          fontSize: 14,
          alignment: 'center',
        },
        myCellPrice: {
          fontSize: 14,
          alignment: 'right',
        },
        myCellSumPrice: {
          fontSize: 14,
          alignment: 'right',
          bold: true,
        },
        myCellSumText: {
          fontSize: 14,
          fillColor: '#D3D3D3',
          alignment: 'left',
          bold: true,
        },
        myCellSumCost: {
          fontSize: 14,
          fillColor: '#D3D3D3',
          alignment: 'right',
          bold: true,
        },
        myCellSumTextCost: {
          fontSize: 14,
          fillColor: '#FF9933',
          alignment: 'left',
          bold: true,
        },
        myCellSumTextCostNum: {
          fontSize: 14,
          fillColor: '#FF9933',
          alignment: 'right',
          bold: true,
        },
        textNote: {
          fontSize: 14,
          color : '#ff0000'
        },
        textWhite: {
          fontSize: 14,
          color : '#ffffff'
        },
      },

      defaultStyle: {
        font: 'THSarabunNew'
      }
    }
    pdfMake.createPdf(docDefinition).open()
  }

  handleOnSubmitCarBook (event) {
    event.preventDefault()
    if (this.state.car_sell_pay.length === 0) {
      alert('กรุณากรอกข้อมูลการชำระเงิน')
      return
    }
    let that = this
    let car_id = this.state.car_id
    let customer_id = this.state.customer_id
    let car_book_status_id = 1

    axios.post(APIURL + '/car_book/', {
      car_id: car_id,
      customer_id: customer_id,
      car_book_status_id: car_book_status_id,
      user_created: this.state.user.user,
      user_updated: this.state.user.user
    })
      .then(response => {
        //alert(response.data)
        that.props.history.push('/cars/list')
        //}
        //this.setState({ car: '', car_id: 0, action: "บันทึก"})
      })
      .catch(function (error) {
        console.log('error:', error)
        //alert("error"+error)
      })
  }

  loadCarById () {
    // var that = this
    axios.get(APIURL + '/car/' + this.state.car_id)
      .then(async res => {
        let car = res.data[0]
        this.setState({
          car: car
        }, async () => {
          await this.makeCarImgBase64()
        })
      }).catch(error => {
      // alert('error' + error)
      console.log('error:', error)
      this.setState({car: {}})
    })
  }

  async makeCarImgBase64() {
    try {
      let car = this.state.car
      await this.loadCarImageBase64(car.car_upload_id)
    } catch (e) {
      console.log(e)
    }
  }

  async loadCarImageBase64(car_upload_id) {
    let uri =  APIURL + '/car/image/' + car_upload_id;
    await axios.get(uri)
      .then(res => {
        let body = res.data.body
        this.setState({ carBase64Img: body })
      }).catch(error => {
        console.log('error:', error)
      })
  }

  loadCarBook () {

    if (this.state.car_id === '') {
      return
    }

    let api_url = APIURL + '/car_book/' + this.state.car_id
    axios.get(api_url)
      .then(res => {
        this.setState({car_book_list: res.data})
      }).catch(error => {
      console.log('พอข้อผิดพลาด: ' + error.message)
      this.setState({car_book_list: []})
    })
  }

  componentWillMount () {
    //const queryString = require('query-string');
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  componentDidMount () {
    // if(this.state.load_data)
    // this.loadDebtorsData()
    const queryString = require('query-string')
    let car = queryString.parse(this.props.location.search)
    this.loadGroupPermission()

    this.setState({car_id: car.car_id}, () => {
      this.loadCompanyLogoBase64();
      this.loadCompanySetting();
      this.loadCarById();
      this.loadCarBook();
    })

    // 9008
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  getManageCarBookDelete(car_sell_pay) {
      // ยกเลิกการจอง
      if (PORT === 9046) {
        return <button onClick={() => {
          this.deleteCarBookWithDeleteCustomer(car_sell_pay)
        }}
                        type="button" className="btn btn-danger btn-sm btn-block">
          <i className="icon-close">&nbsp;ยกเลิกการจอง</i>
        </button>
      } else {
        if(checkGroupPermission(701, this.state.grouppermissions).deleted ){
          return <button onClick={() => {
            this.deleteCarBook(car_sell_pay)
          }}
                         type="button" className="btn btn-danger btn-sm btn-block">
            <i className="icon-close">&nbsp;ยกเลิกการจอง</i>
          </button>
        }else{
          return null
        }
      }
  }

  getManageCarBookEdit(car_sell_pay) {
      if(PORT === 9021 && this.state.user.type === 'sale') {
        return null
      } else {
        if(checkGroupPermission(701, this.state.grouppermissions).modified ){
        return <button
            onClick={() => {
              this.setState({
                book_status: 'edit',
                car_sell_pay_selected: car_sell_pay
              },() => {
                this.toggleCarBookPrimary();
              });
            }}
            type="button"
            className="btn btn-warning btn-sm btn-block"
        >
          <i className="icon-pencil">&nbsp;แก้ไขการจอง</i>
        </button>
        }else{
          return null
        }
      }
  }

  loadCompanySetting () {
    axios.get(APIURL + '/company_setting/1')
      .then(res => {
        let data = res.data
        let company_setting = data.company_setting
        if (company_setting !== null) {
          const is_vat = 0;
          const doc_type = 'RE';
          const doc_config = getDocTypeConfig(company_setting, doc_type);
          const include_vat = 0;

          this.setState({
            company_setting: company_setting,
            doc_type: doc_type,
            doc_config: doc_config,
            is_vat: is_vat,
            include_vat: include_vat
          })
        }
      })
  }

  loadCompanyLogoBase64() {
    let uri =  APIURL + '/company_setting/logoimage/1';

    axios.get(uri)
      .then(res => {
        let body = res.data.body
        this.setState({ logoBase64Img: body })
      }).catch(error => {
        console.log('error: ', error)
      })
  }

  refundCarBook (car_sell_pay) {
    //alert(car_sell_pay.car_book_id )
    this.setState({
      car_sell_pay_id: car_sell_pay.car_sell_pay_id,
      car_sell_pay_count: car_sell_pay.car_sell_pay_count,
      car_sell_pay_selected: car_sell_pay
    }, () => {
      this.toggleRefund()
    })

  }

  toggleRefund () {
    this.setState({
      refund: !this.state.refund
    })
  }

  toggleCarBookPrimary () {
    this.setState({
      carbookprimary: !this.state.carbookprimary
    })
  }

  deleteCarBook (car_sell_pay) {
    //alert(car_sell_pay.car_book_id )
    this.setState({
      car_book_id: car_sell_pay.car_book_id,
      car_sell_pay_id: car_sell_pay.car_sell_pay_id,
      car_sell_pay_selected: car_sell_pay
    }, () => {
      this.toggleDanger()
    })

  }

  deleteCarBookWithDeleteCustomer(car_sell_pay) {
    //alert(car_sell_pay.car_book_id )
    this.setState({
      car_book_id: car_sell_pay.car_book_id,
      car_sell_pay_id: car_sell_pay.car_sell_pay_id,
      car_sell_pay_selected: car_sell_pay,
      customer_id: car_sell_pay.customer_id
    }, () => {
      this.toggleDanger()
    })

  }

  // ลบการจ่ายเงินเพิ่มเติม
  deleteCarBookPayAddition(car_sell_pay) {
      let car_sell_pay_count = car_sell_pay.car_sell_pay_count
      if(window.confirm('ยืนยันการลบรายการ ยอด '+car_sell_pay_count.toLocaleString()+' บาท')) {
        let car_sell_pay_id = car_sell_pay.car_sell_pay_id
        axios.delete(APIURL+'/car_sell_pay/'+car_sell_pay_id)
          .then(res => {
            this.loadCarBook()
          })
      }
  }

  toggleDanger () {
    this.setState({
      danger: !this.state.danger
    })
  }

  //9046 FamilyCar ลบการจองและลบรายชื่อลูกค้าออกจากเมนูข้อมูลลูกค้า
  carBookCancelWithDeleteCustomer(car_book_id){
    let book_rows = 0
    this.state.car_book_list.map((car_sell_pay, index) => {
      if (car_sell_pay.car_book_status_id === 1) { // 1 = จอง 2= ยกเลิก
        book_rows++
      }
    })

    // TODO: ลบการจอง Familycar
    let url = APIURL + '/car_book/cancelwithcustomer/' + car_book_id

    axios.put(url, {
      book_rows: book_rows,
      car_id: this.state.car_id,
      car_sell_pay_id: this.state.car_sell_pay_id,
      customer_id: this.state.customer_id
    }).then(res => {
      if (res.status === 200) {
        this.loadCarBook()
        this.toggleDanger()
        //this.setState({action: "บันทึก"});
      }
    }).catch(error => {
      AlertError('พบข้อผิดพลาด: ' + error.message)
    })
  }

  carBookCancel (car_book_id) {
    var book_rows = 0
    // eslint-disable-next-line
    this.state.car_book_list.map((car_sell_pay, index) => {
      if (car_sell_pay.car_book_status_id === 1) { // 1 = จอง 2= ยกเลิก
        book_rows++
      }
    })

    // TODO: xxxx ยกเลิกการจอง
    // var book_rows = this.state.car_book_list.length
    // let url = APIURL + '/car_book/cancel/' + car_book_id
    let url = APIURL + '/car_book/cancel2/' + car_book_id
    if (port === 9021 || port === 9008) {
      // 9021 sompong
      url = APIURL + '/car_book/cancel2/' + car_book_id
    }

    axios.put(url, {
      book_rows: book_rows,
      car_id: this.state.car_id,
      car_sell_pay_id: this.state.car_sell_pay_id
    }).then(res => {
      if (res.status === 200) {
        this.loadCarBook()
        this.toggleDanger()
        //this.setState({action: "บันทึก"});
      }
    }).catch(error => {
      AlertError('พบข้อผิดพลาด: ' + error.message)
    })
  }

  carBookCancelRefund () {
    let book_rows = 0
    let car_id = this.state.car_id
    let car_sell_pay_id = this.state.car_sell_pay_id
    let car_sell_pay_count = this.state.car_sell_pay_count
    let car_book_id = this.state.car_sell_pay_selected.car_book_id
    let car_book_refund = this.state.car_book_refund
    //at ขอเพิ่มหมายเหตุการคืนเงินจอง
    let book_comment_refund = this.state.book_comment_refund

    // eslint-disable-next-line
    this.state.car_book_list.map((car_sell_pay, index) => {
      if (car_sell_pay.car_book_status_id === 1) {
        book_rows++
      }
    })

    // alert(car_book_refund)

    if (car_book_refund === 0) {
      alert('กรุณากรอกยอดคืนเงิน')
      return
    }

    if (car_book_refund > car_sell_pay_count) {
      alert('ยอดเงินคืนมากกว่ายอดชำระ กรุณาตรวจสอบ')
      return
    }

    // alert("car_sell_pay_id="+car_sell_pay_id)
    // return ;

    axios.put(APIURL + '/car_book/cancel/refund/' + car_book_id, {
      book_rows: book_rows,
      car_id: car_id,
      car_book_id: car_book_id,
      car_sell_pay_id: car_sell_pay_id,
      car_sell_pay_count: car_sell_pay_count,
      car_book_refund: car_book_refund,
      book_comment_refund: book_comment_refund
    }).then(res => {
      if (res.status === 200) {
        this.loadCarBook()
        this.toggleRefund()
        //this.setState({action: "บันทึก"});
      }
    }).catch(error => {
      alert('พอข้อผิดพลาด: ' + error.message)
    })
  }

  async printCarBook(car_sell_pay) {
      let car_id = car_sell_pay.car_id
      let customer_id = car_sell_pay.customer_id
      let car_book_id = car_sell_pay.car_book_id
      let car = this.state.car ;
      let carLeasingList = [];
      let profile = {};
      let carBook = {};

      if (this.state.carBase64Img === '') {
        await this.makeCarImgBase64()
      }

      //load car_leasing
       let uri =  APIURL + '/car_leasing/'+car_id;
       await axios.get(uri)
        .then(res => {
           return carLeasingList = res.data
        }).catch(error => {
          carLeasingList = []
           return null;
       })

      await axios.get(APIURL + '/profile/customer/'+customer_id)
        .then(res => {
          if(res.data.length > 0) {
            return profile = res.data[0];
          }
        }).catch(error => {
          profile = {}
          return null;
      })

      await axios.get(APIURL + '/car_book/book/'+car_book_id)
      .then(res => {
        if(res.data.length > 0) {
          return carBook = res.data[0];
        }
      }).catch(error => {
        carBook = {}
        return null;
      })

      await this.printCarBookPdf(car_sell_pay, car, carLeasingList, carBook, profile);

  }


  async printCarBookCustomer(car_sell_pay) {
    let car_id = car_sell_pay.car_id
    let customer_id = car_sell_pay.customer_id
    let car_book_id = car_sell_pay.car_book_id
    let car = this.state.car ;
    let carLeasingList = [];
    let profile = {};
    let carBook = {};
    let customer = {};

    if (this.state.logoBase64Img === '') {
      // await this.makeCarImgBase64()
      AlertWarning('ไม่พบไฟล์ logo');
    }

    // load car_leasing
    let uri =  APIURL + '/car_leasing/'+car_id;

    await axios.get(uri)
      .then(res => {
        return carLeasingList = res.data
      }).catch(error => {
        carLeasingList = []
        return null;
      })

    await axios.get(APIURL + '/profile/customer/'+customer_id)
      .then(res => {
        if(res.data.length > 0) {
          return profile = res.data[0];
        }
      }).catch(error => {
        profile = {}
        return null;
      })

    await axios.get(APIURL + '/car_book/book/'+car_book_id)
      .then(res => {
        if(res.data.length > 0) {
          return carBook = res.data[0];
        }
      }).catch(error => {
        carBook = {}
        return null;
      })

    await axios.get(APIURL + '/customer/' + customer_id)
      .then(res => {
        if(res.data.length > 0) {
          // this.setState({customer: res.data[0]})
          return customer = res.data;
        }
        //alert (res.data)
      }).catch(error => {
        console.log('error: ', error)
        customer = {}
        return null;
    })

    if (port === 9043) {
      await this.printCarBookPdfCustomerAtCarSale(car_sell_pay, car, carLeasingList, carBook, profile, customer);
    } else {
      await this.printCarBookPdfCustomer(car_sell_pay, car, carLeasingList, carBook, profile, customer);
    }
  }

  // จ่ายค่า dev เพิ่ม
  printCarBookPdfCustomerAtCarSale(car_sell_pay, car, carLeasingList, carBook, profile, customers) {
    const { company_setting } = this.state
    const book_date = moment(carBook.book_date).locale('th').format('LL') //วันที่ออกใบเสร็จหรือวันที่จ่าย

    let juristic_number = company_setting.juristic_number
    let company_tel = company_setting.company_tel === '' ? '-' : company_setting.company_tel

    let customer = customers[0]
    const customer_id = customer.customer_id
    const tax_invoice_name = customer.customer_name+' '+ customer.customer_lastname
    const tax_invoice_address1 = customer.customer_address
    const tax_invoice_address2 = ''
    let tax_invoice_tax_id = customer.customer_card_id === null ? '' : customer.customer_card_id
    const customer_mobile = customer.customer_mobile //

    if (tax_invoice_tax_id.length===13) {
      tax_invoice_tax_id = tax_invoice_tax_id.replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, "$1-$2-$3-$4-$5")
    }

    const car_name = car.car_name //ยี่ห้อรถ
    const car_license_plate = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
      this.state.car.car_license_plate_new : this.state.car.car_license_plate_old

    const province_name = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
      this.state.car.province_name_new : this.state.car.province_name_old
    const car_color_name = car.car_color_name


    let base64Img = this.state.logoBase64Img
    let company_name = company_setting.company_name
    let company_address = ''
    let company_address_all = company_setting.company_address

    let company_arr = company_address_all.split('อำเภอ')
    if (company_arr.length === 2) {
      let company_arr1 = company_arr[0]
      let company_arr2 = company_arr[1]
      company_address = company_arr1 +'\nอำเภอ'+ company_arr2
    } else {
      company_address = company_address_all
    }

    // xxx
    let logo_width = 110
    if (company_address.length >=60 ) {
      logo_width = 76
    } else if (company_address.length >=57 ) {
      logo_width = 96
    }

    let headerLeft = {image: base64Img, width: logo_width, rowSpan: 3};

    // margin: [left, top, right, bottom]
    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [35, 25, 35, 25], // margin: [left, top, right, bottom]
      content: [
        {
          table: {
            widths: [ '20%', '60%', '20%' ],
            body: [
              [
                headerLeft,
                {text: company_name, style: 'bodyhead'},
                {text: ' ', style: 'normalRight', rowSpan: 3},
              ],
              [
                '',
                {text:  company_address , style: 'normalCenter'},
                '',
              ],
              [
                '',
                {text:  '' , style: 'normalCenter'},
                '',
              ],
              [
                {text: '' , style: 'normalCenter'},
                {text: 'ใบรับมัดจำจองสินค้า', style: 'titleLarge', margin: [0, 0, 0, 0]},
                {text: '', style: 'normalRight'},
              ],
              [
               {text: 'วันที่ '+ book_date , style: 'normal', colSpan: 2, margin: [ 0, 8, 0, 8 ]},
                '',
                {text: '', style: 'normalRight'},
              ],
            ],
          },
          layout: 'noBorders'
        },
      ],
      styles: {
        normal: {
          fontSize: 16,
        },

      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    }

    pdfMake.createPdf(docDefinition).open();

  }

  printCarBookPdfCustomer(car_sell_pay, car, carLeasingList, carBook, profile, customers){
    const {
      company_setting,
    } = this.state

    const book_date = moment(carBook.book_date).locale('th').format('LL') //วันที่ออกใบเสร็จหรือวันที่จ่าย

    let customer = customers[0]
    const customer_id = customer.customer_id
    const tax_invoice_name = customer.customer_name+' '+' '+ customer.customer_lastname
    const tax_invoice_address1 = customer.customer_address
    const tax_invoice_address2 = ''
    let tax_invoice_tax_id = customer.customer_card_id === null ? '' : customer.customer_card_id
    const customer_mobile = customer.customer_mobile //

    let tax_invoice_name_pad = stringUtil.addSpacePaddingFill(tax_invoice_name, 48)
    let tax_invoice_address1_pad = stringUtil.addSpacePaddingFill(tax_invoice_address1, 50)
    let tax_invoice_tax_id_pad = stringUtil.addSpacePaddingFill(tax_invoice_tax_id, 30)
    let customer_mobile_pad = stringUtil.addSpacePaddingFill(customer_mobile, 22)


    // n.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    if (tax_invoice_tax_id.length===13) {
      tax_invoice_tax_id = tax_invoice_tax_id.replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, "$1-$2-$3-$4-$5")
    }

    const car_name = car.car_name //ยี่ห้อรถ
    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 38)
    // const car_color_name = this.state.car.car_color_name //สี
    //const car_license_plate_new = car.car_license_plate_new === '' || car.car_license_plate_new === null ? car.car_license_plate_old: car.car_license_plate_new // ทะเบียนรถยนต์
    //const car_province_name_new = car.car_license_plate_new === '' || car.car_license_plate_new === null ?  car.province_name_old.trim() : car.province_name_new.trim() // จังหวัด
    const car_license_plate = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
        this.state.car.car_license_plate_new : this.state.car.car_license_plate_old

    const province_name = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
        this.state.car.province_name_new : this.state.car.province_name_old
    const car_color_name = car.car_color_name
    let car_color_name_pad = stringUtil.addSpacePaddingFill(car_color_name, 16)

    let car_license_plate_pad = stringUtil.addSpacePaddingFill(car_license_plate, 16)
    let province_name_pad = stringUtil.addSpacePaddingFill(province_name, 16)
    // const car_explain = car.car_explain
    // const car_sale_price = car.car_sale_price.toLocaleString()
    // const customer_name = car_sell_pay.customer_name //ชื่อลูกค้า
    // const customer_lastname = car_sell_pay.customer_lastname //นามสกุลลูกค้า
    // const customer_address = car_sell_pay.customer_address //ที่อยู่ลูกค้า

    // ข้อมูลการจอง
    const cost_car_book = carBook.cost_car_book.toFixed(2).toString()
    const cost_pledge = carBook.cost_pledge.toFixed(2).toString()
    const cost_car_book_bath = ThaiBaht(cost_car_book)
    const cost_pledge_bath = ThaiBaht(cost_pledge)
    const sales_type_name = car_sell_pay.sales_type_name

    let cost_car_book_str = NumberUtil.addCommas(parseInt(cost_car_book, 10))
    let cost_car_book_pad = stringUtil.addSpacePaddingFill(cost_car_book_str, cost_car_book_str==='' || cost_car_book_str === 0 ? 24 : 28)

    let cost_car_book_text = ThaiBaht(cost_car_book)
    let cost_car_book_text_pad = stringUtil.addSpacePaddingFill(cost_car_book_text, cost_car_book_text === '' || cost_car_book_text === '0' ? 50 : 60)

    let cost_pledge_str = NumberUtil.addCommas(parseInt(cost_pledge, 10))
    let cost_pledge_pad = stringUtil.addSpacePaddingFill(cost_pledge_str, cost_pledge_str==='' || cost_pledge_str === 0 ? 22 : 24)

    let cost_pledge_text = ThaiBaht(cost_pledge)
    let cost_pledge_text_pad = stringUtil.addSpacePaddingFill(cost_pledge_text, cost_pledge_text === '' || cost_pledge_text === '0' ? 28 : 30)

    let sales_type_name_pad = stringUtil.addSpacePaddingFill(sales_type_name, 30)

    let base64Img = this.state.logoBase64Img
    let company_name = company_setting.company_name
    let company_address = ''
    let company_address_all = company_setting.company_address

    let company_name_pad = stringUtil.addSpacePaddingFill(company_name, 60)

    // อำเภอ
    let company_arr = company_address_all.split('อำเภอ')
    if (company_arr.length === 2) {
      let company_arr1 = company_arr[0]
      let company_arr2 = company_arr[1]
      company_address = company_arr1 +'\nอำเภอ'+ company_arr2
    } else {
      company_address = company_address_all
    }

    let juristic_number = company_setting.juristic_number
    let company_tel = company_setting.company_tel === '' ? '-' : company_setting.company_tel

    // vat
    // is_vat vat
    let is_vat = company_setting.is_vat
    let vat = parseFloat(company_setting.vat)

   let price_data = []
   let price_sale = {
      columns: [
        { width: '15%',
          text: 'ราคาเงินสด', style: 'normal',
        },
        { width: '25%',
          text: NumberUtil.addCommas(cost_car_book)+'  บาท', style: 'normalRight',
        },
        { width: '60%',
          text: '', style: 'normal',
        },
      ],
      columnGap: 0,
      lineHeight:1.2
    }

    price_data.push(price_sale)

    if (is_vat === 1) {
      let price_sale = parseFloat(carBook.cost_car_book)
      let vat_amount = parseFloat(price_sale * (vat/100)).toFixed(2)
      let tmp_net_sale = parseFloat(price_sale) + parseFloat(vat_amount)

      let net_sale = tmp_net_sale.toFixed(2).toString()
      // const vat_bath = ThaiBaht(vat_amount)
      const net_sale_bath = ThaiBaht(net_sale)
        // carBook.cost_pledge.toFixed(2).toString()

      let price_vat = {
        columns: [
          { width: '15%',
            text: 'ภาษามูลค่าเพิ่ม', style: 'normal',
          },
          { width: '25%',
            text: NumberUtil.addCommas(vat_amount)+'  บาท', style: 'normalRight',
          },
          { width: '60%',
            text: '', style: 'normal',
          },
        ],
        columnGap: 0,
        lineHeight:1.2
      }
      let price_net = {
        columns: [
          { width: '15%',
            text: 'รวมทั้งหมด', style: 'normal',
          },
          { width: '25%',
            text: NumberUtil.addCommas(net_sale)+'  บาท', style: 'normalRight',
          },
          { width: '1%',
            text: '', style: 'normal',
          },
          { width: '59%',
            text: ' ('+net_sale_bath+')', style: 'normal',
          },
        ],
        columnGap: 0,
        lineHeight:1.2
      }

      // price_data.push(price_vat)
      // price_data.push(price_net)

    }

    let logo_width = 110
    if (company_address.length >=60 ) {
      logo_width = 76
    } else if (company_address.length >=57 ) {
      logo_width = 96
    }

    let headerLeft = {image: base64Img, width: logo_width, rowSpan: 3};

    let car_book_id = carBook.car_book_id

    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [35, 26, 35, 25], // margin: [left, top, right, bottom]
      content: [
        {
          table: {
            widths: [ '20%', '60%', '20%' ],
            body: [
              [
                headerLeft,
                {text: company_name, style: 'bodyhead'},
                {text: ' ', style: 'normalRight', rowSpan: 3},
              ],
              [
                '',
                {text:  company_address , style: 'normalCenter'},
                '',
              ],
              [
                '',
                {text:  '' , style: 'normalCenter'},
                '',
              ],
              [
                {text: '' , style: 'normalCenter'},
                {text: 'ใบรับมัดจำจองสินค้า', style: 'titleLarge', margin: [0, -15, 0, 10]},
                {text: '', style: 'normalRight'},
              ],
            ],
          },
          layout: 'noBorders'
        },
        {
          columns: [
            { width: '50%',
              text: '', style: 'normalRight',
            },
            { width: '25%', style: ['normalRight'], margin: [0, 0, 0, 5],
              text: 'วันที่',
            },
            { width: '25%', style: ['normalCenter', 'bottomLine'],
              text: stringUtil.addSpacePaddingRightLeft(book_date, 26),
            },
          ],
        },
        {
          columns: [
            { width: '50%',
              text: '', style: 'normalRight',
            },
            { width: '25%', style: ['normalRight'],
              text: 'เลขที่ใบจอง',
            },
            { width: '*', style: ['normalCenter', 'bottomLine'], margin: [0, 0, 0, 10],
              text: stringUtil.addSpacePaddingRightLeft('   ' +car_book_id+'   ', 34),
            },
          ],
        },
        {
          columns: [
            { width: 48,
              text: 'ชื่อผู้ซื้อ', style: 'normal',
            },
            { width: '43%', style: ['normalLeft', 'bottomLine'],
              text: tax_invoice_name_pad,
            },
           { width: 131, style: ['normalLeft'], margin: [0, 0, 0, 0],
              text: 'เลขบัตรประจำตัวประชาชน',
            },
            { width: '*', style: ['normalLeft', 'bottomLine'],
              text: tax_invoice_tax_id_pad,
            },
          ],
          columnGap: 0,
          lineHeight:1.2,
          margin: [ 0, 0, 0, 0 ]
        },
        {
          columns: [
            { width: 28, style: ['normalLeft'],
              text: 'ที่อยู่',
            },
            { width: '65%', style: ['normalLeft', 'bottomLine'],
              text: tax_invoice_address1_pad,
            },
            { width: 70, style: ['normalLeft'], margin: [0, 0, 0, 0],
              text: 'เบอร์โทรศัพท์',
            },
            { width: '*', style: ['normalLeft', 'bottomLine'],
              text: customer_mobile_pad,
            },
          ],
          columnGap: 0,
          lineHeight:1.2
        },
        {
          columns: [
            { width: '20%', style: ['normalLeft'],
              text: 'ได้ตกลงซื้อสินค้าจาก ',
            },
            { width: '40%', style: ['normalLeft', 'bottomLine'],
              text: company_name_pad,
            },
            { width: '20%', style: ['normalLeft'],
              text: ' รายละเอียดดังนี้',
            },
          ],
          columnGap: 0,
          lineHeight:1.2
        },
        {
          columns: [
            /*{ width: '100%',
              text: 'รถยนต์...'+car_name+'... สี...'+car_color_name+'... เลขทะเบียน...'+car_license_plate+'... จังหวัด...'+province_name+'...', style: 'normal',
            },*/
            { width: 40, style: ['normalLeft'],
              text: 'รถยนต์ ',
            },
            { width: '42%', style: ['normalLeft', 'bottomLine'],
              text: car_name_pad,
            },
            { width: 22, style: ['normalLeft'],
              text: 'สี',
            },
            { width: '11%', style: ['normalLeft', 'bottomLine'],
              text: car_color_name_pad,
            },
            { width: '11%', style: ['normalLeft'],
              text: 'ทะเบียนรถ',
            },
            { width: '30%', style: ['normalLeft', 'bottomLine'],
              text: car_license_plate_pad + ' ' + province_name_pad,
            },
              //รวมบรรทัดเดียวกันเนื่องจากบรรทัดตกขึ้นหน้าใหม่
            /*{ width: '7%', style: ['normalLeft'],
              text: 'จังหวัด',
            },
            { width: '13%', style: ['normalLeft', 'bottomLine'],
              text: province_name_pad,
            },*/
          ],
          columnGap: 0,
          lineHeight:1.2
        },
        {
          columns: [
            /*{ width: '100%',
              text: 'ราคาเงินสด...'+NumberUtil.addCommas(cost_car_book)+'...บาท (...'+cost_car_book_bath+'...)', style: 'normal',
            },*/
            { width: '13%', style: ['normalLeft'],
              text: 'ราคาเงินสด ',
            },
            { width: '17%', style: ['normalLeft', 'bottomLine'],
              text: cost_car_book_pad,
            },
            { width: '5%', style: ['normalLeft'],
              text: 'บาท',
            },
            { width: '6%', style: ['normalLeft'],
              text: '( ',
            },
            { width: '40%', style: ['normalLeft','bottomLine'],
              text: '    ' + cost_car_book_text_pad,
            },
            { width: '4%', style: ['normalLeft'],
              text: ' )',
            },
          ],
          columnGap: 0,
          lineHeight:1.2
        },
        {
          columns: [
            /*{ width: '100%',
              text: 'โดยในวันนี้ผู้ซื้อได้ชำระเงินมัดจำไว้เป็นจำนวนเงิน...'+NumberUtil.addCommas(cost_pledge)+'...บาท (...'+cost_pledge_bath+'...) การชำระ...'+sales_type_name+'...', style: 'normal',
            },*/
            { width: '40%', style: ['normalLeft'],
              text: 'โดยในวันนี้ผู้ซื้อได้ชำระเงินมัดจำไว้เป็นจำนวนเงิน',
            },
            { width: '15%', style: ['normalLeft', 'bottomLine'],
              text: cost_pledge_pad,
            },
            { width: '5%', style: ['normalLeft'],
              text: 'บาท',
            },
            { width: '4%', style: ['normalLeft'],
              text: '(',
            },
            { width: '22%', style: ['normalLeft','bottomLine'],
              text: cost_pledge_text_pad,
            },
            { width: '2%', style: ['normalLeft'],
              text: ')',
            },
          ],
          columnGap: 0,
          lineHeight:1.2
        },
        {
          columns: [
            /*{ width: '100%',
              text: 'โดยในวันนี้ผู้ซื้อได้ชำระเงินมัดจำไว้เป็นจำนวนเงิน...'+NumberUtil.addCommas(cost_pledge)+'...บาท (...'+cost_pledge_bath+'...) การชำระ...'+sales_type_name+'...', style: 'normal',
            },*/
            { width: '12%', style: ['normalLeft'],
              text: 'ชำระโดย',
            },
            { width: '*', style: ['normalLeft', 'bottomLine'],
              text: sales_type_name_pad,
            },
          ],
          columnGap: 0,
          lineHeight:1.2
        },
        {
          columns: [
            { width: '100%',
              text: 'กำหนดรับรถยนต์ภายในวันที่ .....................................................................', style: 'normal',
            },
          ],
          columnGap: 0,
          lineHeight:1.2
        },
        /*{
          columns: [
            { width: '15%',
              text: 'ที่อยู่', style: 'normal',
            },
            { width: '85%', style: 'normal',
              text: tax_invoice_address1,
            },
          ],
          columnGap: 0,
          lineHeight:1
        },*/
        {
          columns: [
            { width: '5%',
              text: '', style: 'normal',
            },
            { width: '95%', style: 'normal',
              text: 'หากว่าผู้ซื้อไม่มารับรถยนต์ภายในกำหนด ผู้ซื้อยินยอมให้ผู้ขายริบมัดจำ และหากว่าผู้ขายไม่สามารถโอนกรรมสิทธิ์',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 8, 0, 0 ]
        },
        {
          columns: [
            { width: '100%', style: 'normal',
              text: 'ให้กับผู้ซื้อได้ ผู้ขายยินยอมคืนเงินให้ทั้งหมด',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 0, 0, 0 ]
        },
        {
          columns: [
            { width: '50%',
              text: 'เงื่อนไขการชำระ', style: 'bodyhead',
            },
            { width: '50%', style: 'bodyhead',
              text: 'อุปกรณ์เพิ่มเติม',
            },
          ],
          columnGap: 1,
          lineHeight:1,
          margin: [ 0, 8, 0, 4 ]
        },
        {
          columns: [
            { width: '50%',
              text: '............................................................................', style: 'bodyhead',
            },
            { width: '50%',
              text: '............................................................................', style: 'bodyhead',
            },
          ],
          columnGap: 1,
          lineHeight:1,
          margin: [ 0, 4, 0, 0 ]
        },
        {
          columns: [
            { width: '50%',
              text: '............................................................................', style: 'bodyhead',
            },
            { width: '50%',
              text: '............................................................................', style: 'bodyhead',
            },
          ],
          columnGap: 1,
          lineHeight: 1,
          margin: [ 0, 4, 0, 0 ]
        },
         {
          columns: [
            { width: '50%',
              text: '............................................................................', style: 'bodyhead',
            },
            { width: '50%',
              text: '............................................................................', style: 'bodyhead',
            },
          ],
          columnGap: 1,
          lineHeight: 1,
          margin: [ 0, 4, 0, 0 ]
        },
        /* {
         columns: [
           { width: '50%',
             text: '............................................................................', style: 'bodyhead',
           },
           { width: '50%',
             text: '............................................................................', style: 'bodyhead',
           },
         ],
         columnGap: 1,
         lineHeight: 1,
         margin: [ 0, 8, 0, 0 ]
       },
      {
         columns: [
           { width: '50%',
             text: '............................................................................', style: 'bodyhead',
           },
           { width: '50%',
             text: '............................................................................', style: 'bodyhead',
           },
         ],
         columnGap: 1,
         lineHeight: 1,
         margin: [ 0, 8, 0, 0 ]
       },*/
        {
          columns: [
            {
              width: '100%',
              style: 'normalCenter',
              text: 'ลงชื่อ ............................................................................ ผู้ซื้อ / ผู้สั่งจอง',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 25, 0, 0 ]
        },
        {
          columns: [
            { width: '28%',
              text: '', style: 'normalCenter',
            },
            { width: '50%', style: 'normal',
              text: ' (..............................................................................) ',
            },
            { width: '22%',
              text: '', style: 'normalCenter',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 4, 0, 0 ]
        },
        {
          columns: [
            { width: '100%', style: 'normalCenter',
              text: 'ลงชื่อ ............................................................................ ผู้ขาย / ผู้รับจอง',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 10, 0, 0 ]
        },
        {
          columns: [
            { width: '28%',
              text: '', style: 'normalCenter',
            },
            { width: '50%', style: 'normal',
              text: ' (..............................................................................) ',
            },
            { width: '22%',
              text: '', style: 'normalCenter',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 4, 0, 0 ]
        },
        //
        {
          columns: [
            { width: '100%', style: 'normalCenter',
              text: 'ลงชื่อ ............................................................................ ผู้รับเงินจอง',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 14, 0, 0 ]
        },
        {
          columns: [
            { width: '29%',
              text: '', style: 'normalCenter',
            },
            { width: '50%', style: 'normal',
              text: ' (..............................................................................) ',
            },
            { width: '21%',
              text: '', style: 'normalCenter',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 4, 0, 0 ]
        },
        {
          columns: [
            { width: '2%',
              text: '', style: 'normalCenter',
            },
            { width: '98%', style: 'normal',
              text: ' *** หมายเหตุ หากผู้สั่งจอง 1. ยกเลิก 2. ติด BlACKLIST 3. หรือไม่ติดต่อจัดไฟแนนซ์ภายใน 15 วัน ผู้รับเงินจอง มีสิทธิ์ยึดมัดจำได้ 100% ',
            },
          ],
          columnGap: 0,
          lineHeight:1,
          margin: [ 0, 12, 0, 0 ]
        },

      ],//content
      styles: {
        normal: {
          fontSize: 15,
        },
        normalCenter: {
          fontSize: 15,
          alignment: 'center'
        },
        normalRight: {
          fontSize: 15,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 15,
          alignment: 'left',
        },
        bodyhead: {
          fontSize: 17,
          bold: true,
          alignment: 'center'
        },
        titleLarge: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        textNormal: {
          fontSize: 14,
        },
        textReserve: {
          fontSize: 14,
        },
        textProfile: {
          fontSize: 14,
          noWrap: false
        },
        tableCar: {
          margin: [0, 5, 0, 10]
        },
        bottomLine: {
          decoration: 'underline',
          decorationStyle: 'dotted',
        }
      },//styles
      defaultStyle: {
        font: 'THSarabunNew'
      }
    }//docDefinition

    pdfMake.createPdf(docDefinition).open();
  }

  printCarBookPdf(car_sell_pay, car, carLeasingList, carBook, profile){
    const car_name = car.car_name //ยี่ห้อรถ
    const car_license_plate = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
        this.state.car.car_license_plate_new : this.state.car.car_license_plate_old

    const province_name = this.state.car.car_license_plate_new !== '' &&  this.state.car.car_license_plate_new !==  this.state.car.car_license_plate_old ?
        this.state.car.province_name_new : this.state.car.province_name_old

    const car_explain = car.car_explain === '' || car.car_explain === null ? '' : '(' + car.car_explain + ')'
    const car_sale_price = car.car_sale_price.toLocaleString()

    const customer_name = car_sell_pay.customer_name //ชื่อลูกค้า
    const customer_lastname = car_sell_pay.customer_lastname //นามสกุลลูกค้า
    const customer_address = car_sell_pay.customer_address //ที่อยู่ลูกค้า
    const customer_mobile = car_sell_pay.customer_mobile //
    const date_pay = moment(car_sell_pay.date_pay).locale('th').format('LL') //วันที่ออกใบเสร็จหรือวันที่จ่าย
    const date_book = moment(car_sell_pay.car_book_date).locale('th').format('LL') //วันที่ทำการจอง

    let base64Img = this.state.carBase64Img
    if (this.state.carBase64Img === '') {
      AlertWarning('กรุณารอสักครู่ระบบกำลังโหลดภาพรถ')
      return
    }

    var leasings = []

    var carDetail1 = [
      {
        colSpan: 4,
        border: [false, false, false, false],
        text: car_name ,
        style: 'textReserveBold'
      },'','',''
    ];
    var carDetail2 = [
      {
        colSpan: 4,
        border: [false, false, false, false],
        text: 'ทะเบียนรถ '+car_license_plate + ' '+ province_name ,
        style: 'textReserveBold'
      },'','',''
    ];
    var carDetail3 = [
      {
        colSpan: 4,
        border: [false, false, false, false],
        text: 'ราคา '+car_sale_price + ' บาท ' + car_explain ,
        style: 'textReserveBold'
      },'','',''
    ];

    var leasingHead = ['ธนาคาร', 'ราคากลาง' , 'เครดิต', 'ไม่เครดิต']

    leasings.push(carDetail1)
    leasings.push(carDetail2)
    leasings.push(carDetail3)
    leasings.push(leasingHead)

    carLeasingList.map(leasing => {
      let car_leasing_name = leasing.car_leasing_name
      let car_leasing_price_median = leasing.car_leasing_price_median
      let car_leasing_price = leasing.car_leasing_price
      let car_leasing_price_nc = leasing.car_leasing_price_nc
      let data = [car_leasing_name, car_leasing_price_median, car_leasing_price, car_leasing_price_nc] ;
      return leasings.push(data)
    })

    let discount = Number(car.car_sale_price) - Number(carBook.cost_car_book)
    let discount_price = discount > 0 ? discount : 0

    let carReserve = [];
    let dateBook = [
      {
        border: [false, false, false, false],
        text: 'วันที่จองรถ ',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= '+ ' ' +date_book,
        style: 'textReserve'
      }
    ];

    let datePay = [
      {
        border: [false, false, false, false],
        text: 'วันที่ชำระเงินจอง ',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= '+ ' ' +date_pay,
        style: 'textReserve'
      }
    ];


    let cost_car_book = [
      {
        border: [false, false, false, false],
        text: 'ราคาขาย (ราคารับจอง)',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_car_book.toLocaleString(),
        style: 'textReserve'
      }
    ];

    //
    let car_discount = [
      {
        border: [false, false, false, false],
        text: 'ส่วนลด',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +discount_price.toLocaleString(),
        style: 'textReserve'
      }
    ]

    // ราคาไฟแนนท์ -> ยอดไฟแนนท์อนุมัติ
    let cost_car_finance = [
      {
        border: [false, false, false, false],
        text: 'ยอดไฟแนนท์อนุมัติ / ยอดทำสัญญากับเต็นท์',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_car_finance.toLocaleString(),
        style: 'textReserve'
      }
    ];

    let cost_down = [
      {
        border: [false, false, false, false],
        text: 'เงินดาวน์รถ',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_down.toLocaleString(),
        style: 'textReserve'
      }
    ];
    let cost_finance = [
      {
        border: [false, false, false, false],
        text: 'ค่าโอน+ค่าจัด',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_finance.toLocaleString(),
        style: 'textReserve'
      }
    ];
    /*
    ยกเลิก รวมกับ cost_finance
    var cost_check = [
      { border: [false, false, false, false],
        text: 'ค่าตรวจ+ค่าจัด'
      },{
        border: [false, false, false, false],
        text: '= '+carBook.cost_check.toLocaleString()
      }
    ];*/
    let cost_insurance = [
      {
        border: [false, false, false, false],
        text: 'ค่าประกันภัย',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_insurance.toLocaleString(),
        style: 'textReserve'
      }
    ];
    let cost_car_tax = [
      {
        border: [false, false, false, false],
        text: 'ค่า พ.ร.บ./ภาษีรถ',
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_car_tax.toLocaleString(),
        style: 'textReserve'
      }
    ];
    var cost_car_out = [
      {
        border: [false, false, false, false],
        text: '* รวมเงินทั้งสิ้น *',
        style: 'textReserveBold'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_car_out.toLocaleString(),
        style: 'textReserveBold'
      }
    ];
    let cost_pledge = [
      {
        border: [false, false, false, false],
        text: 'หัก เงินมัดจำรถ',
        style: 'textReserveBold'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_pledge.toLocaleString(),
        style: 'textReserveBold'
      }
    ];
    let cost_car_out_remain = [
      {
        border: [false, false, false, false],
        text: 'ยอดชำระ ณ วันออกรถ',
        style: 'textReserveBold'
      },
      {
        border: [false, false, false, false],
        text: '= ' + ' ' +carBook.cost_car_out_remain.toLocaleString(),
        style: 'textReserveBold'
      }
    ];


    let booking_by_label = ''
    if (PORT === 9008){
      booking_by_label = 'เซลล์'
    }else if (PORT === 9048){
      booking_by_label = 'ผู้ขาย'
    }else {
      booking_by_label = 'ผู้รับจอง'
    }

    let employee_book = ''
    if (PORT === 9048){
      employee_book = carBook.employee_book_id === 0 || carBook.employee_book_id === '' ? 'ไม่ระบุ' : carBook.employee_book_name + ' ' + carBook.employee_book_lastname
    }else {
      employee_book = carBook.booking_by
    }

    let booking_by = [
      {
        border: [false, false, false, false],
        text: booking_by_label,
        style: 'textReserve'
      },
      {
        border: [false, false, false, false],
        text: '= '+ employee_book,
        style: 'textReserve'
      }
    ];


    carReserve.push(dateBook)
    carReserve.push(datePay)
    carReserve.push(cost_car_book)
    carReserve.push(car_discount)
    carReserve.push(cost_car_finance)
    carReserve.push(cost_down)
    carReserve.push(cost_finance)
    // carReserve.push(cost_check)
    carReserve.push(cost_insurance)
    carReserve.push(cost_car_tax)
    carReserve.push(cost_car_out)
    carReserve.push(cost_pledge)
    carReserve.push(cost_car_out_remain)
    carReserve.push(booking_by)

    if(Object.keys(profile).length === 0){
      profile = {
        profile_from: '',
        profile_fn1: '',
        profile_fn2: '',
        profile_fn3: '',
        profile_name: '',
        profile_ncb1: '',
        profile_guarantor1: '',
        profile_guarantor_ncb1: '',
        profile_guarantor2: '',
        profile_guarantor_ncb2: '',
      }
    }

    var tableProfile = [];
    var profile_from = [
      {
        border: [false, false, false, false],
        text: 'ช่องทาง :',
        style: 'textProfile'
      }, {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? ' ': profile.profile_from ,
        style: 'textProfile'
      },''
    ];
    // profile_address
    var profile_address = [
      {
        border: [false, false, false, false],
        text: 'ลูกค้ามาจาก : ',
        style: 'textProfile'
      }, {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? ' ': profile.profile_address ,
        style: 'textProfile'
      },''
    ];

    var profile_fn1 = [
      {
        border: [false, false, false, false],
        text: 'F/N : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? '  ' : profile.profile_fn1,
        style: 'textProfile'
      },''
    ];
    var profile_fn2 = [
      {
        border: [false, false, false, false],
        text: 'F/N : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? '  ' : profile.profile_fn2,
        style: 'textProfile'
      },''
    ];

    var profile_fn3 = [
      {
        border: [false, false, false, false],
        text: 'F/N : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? '  ' : profile.profile_fn3,
        style: 'textProfile'
      },''
    ];

    var profile_name = [
      {
        border: [false, false, false, false],
        text: 'PROFILE : ',
        style: 'textProfile'
      }, {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? ' ': profile.profile_name,
        style: 'textProfile'
      },''
    ];

    var profile_ncb1 = [
      {
        border: [false, false, false, false],
        text: 'NCB : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? ' ': profile.profile_ncb1,
        style: 'textProfile'
      },''
    ];

    var profile_guarantor1 = [
      {
        border: [false, false, false, false],
        text: 'ค้ำ 1 : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+Object.keys(profile).length === 0 ? ' ': profile.profile_guarantor1,
        style: 'textProfile'
      },''
    ];

    var profile_guarantor_ncb1 = [
      {
        border: [false, false, false, false],
        text: 'ค้ำ 1 NCB : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? ' ': profile.profile_guarantor_ncb1,
        style: 'textProfile'
      },''
    ];

    var profile_guarantor2 = [
      {
        border: [false, false, false, false],
        text: 'ค้ำ 2 : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? ' ': profile.profile_guarantor2,
        style: 'textProfile'
      },''
    ];

    var profile_guarantor_ncb2 = [
      {
        border: [false, false, false, false],
        text: 'ค้ำ 2 NCB : ',
        style: 'textProfile'
      },
      {
        colSpan: 2,
        border: [false, false, false, false],
        text: ''+ Object.keys(profile).length === 0 ? ' ': profile.profile_guarantor_ncb2,
        style: 'textProfile'
      },''
    ];


    tableProfile.push(profile_from)
    tableProfile.push(profile_address)
    tableProfile.push(profile_fn1)
    tableProfile.push(profile_fn2)
    tableProfile.push(profile_fn3)
    tableProfile.push(profile_name)
    tableProfile.push(profile_ncb1)
    tableProfile.push(profile_guarantor1)
    tableProfile.push(profile_guarantor_ncb1)
    tableProfile.push(profile_guarantor2)
    tableProfile.push(profile_guarantor_ncb2)

    // this.convertImgToBase64URL(car_url, function(base64Img){

     let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 30, 30, 25], // margin: [left, top, right, bottom]
      content: [
        { text: 'ใบจองรถ', style: 'bodyhead', lineHeight:1.3},
        {
          style: 'tableCar',
          table: {
            widths: ['16%','42%', '42%'],
            heights: [200],
            body: [
              [
                {
                  border: [false, false, false, false],
                  width: 75,
                  image: base64Img
                  //text: 'base64Img'
                }
                ,
                {
                  border: [false, false, false, false],
                  table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    body: leasings
                  }
                }
               ,
                {
                border: [false, false, false, false],
                table: {
                  widths: ['50%','50%'],
                  body: carReserve
                }
               }],
            ]
          }
        },
        {
          style: 'tableCar',
          table: {
            body: [
              [{
                border: [false, false, false, false],
                text: 'ชื่อลูกค้า     :    '+customer_name+' '+ customer_lastname+ ' ('+ customer_mobile+')',
                style: 'textReserveBold'
              }],
              [{
                border: [false, false, false, false],
                text: 'ที่อยู่ลูกค้า   :    '+customer_address,
                style: 'textReserveBold'
              }]
            ]
          }
        },
        {
          style: 'tableCar',
          table: {
            heights: function (row) {
              // return (row + 1) * 25;
              return 20;
            },
            widths: [60,'auto','auto'],
            body: tableProfile
          }
        },
      ],//content
      styles: {
        bodyhead: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        textNormal: {
          fontSize: 14,
        },
        textReserve: {
          fontSize: 14,
        },
        textReserveBold: {
          fontSize: 14,
          bold: true,
        },
        textProfile: {
          fontSize: 14,
          noWrap: false
        },
        tableCar: {
          margin: [0, 5, 0, 10]
        },
      },//styles
      defaultStyle: {
        font: 'THSarabunNew'
      }
    }//docDefinition

     pdfMake.createPdf(docDefinition).open();
    // }); //end image

  }

  convertImgToBase64URL(url, callback){

    var img = new Image();
    // img.crossOrigin = 'anonymous';
    // img.crossOrigin = 'use-credentials';
    // img.crossOrigin = '*';
    img.allowTaint = true;
    img.onload = function(){
      let canvas = document.createElement('CANVAS')
      let  ctx = canvas.getContext('2d');
      let dataURL
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  }

  convertImgToBase64URLPromise(url, outputFormat) {
    return new Promise(function (fulfill, reject){
      const img = new Image();
      // img.crossOrigin = 'Anonymous';
      // img.allowTaint = true;
      img.crossOrigin = 'anonymous';
      // img.crossOrigin = 'http://localhost:3000';
      img.onload = function () {
        var canvas = document.createElement('CANVAS'),
          ctx = canvas.getContext('2d'), dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        // dataURL = canvas.toDataURL(outputFormat);
        dataURL = canvas.toDataURL(null);
        if (dataURL === null) {
          reject(null);
        } else {
          fulfill(dataURL);
        }
        canvas = null;
      };
      img.src = url;
    });
  }

  async addPayment(car_sell_pay_add) {
    await this.loadSalesTypeData()
    await this.loadBankData()
    await axios.get(APIURL + '/car_sell_list_pay/')
      .then(res => {
        this.setState({
          car_sell_list_pay: res.data,
          car_sell_pay_add: car_sell_pay_add
        }, ()=> {
          this.toggleAddPayment()
        })
      }).catch(error => {
        this.setState({car_sell_list_pay: []});
      })
  }

  toggleAddPayment() {
      this.setState({
        toggle_add_payment: !this.state.toggle_add_payment
      });
  }

  carSalesPayTypeChange (obj){
    let value = obj.target.value

    this.setState({sales_type_id: value}, ()=>{
      if (value === '1') {
        this.refs.bank_id.value = '1'
      } else {
        this.refs.bank_id.value = ''
      }
    });
  }

  async loadSalesTypeData () {
    await axios.get(APIURL + '/sales_type/')
      .then(res => {
        this.setState({sales_type: res.data})

      }).catch(error => {
        this.setState({sales_type: []})
      })
  }

  async loadBankData () {
    await axios.get(APIURL + '/bank/')
      .then(res => {
        this.setState({bank: res.data})

      }).catch(error => {
        this.setState({bank: []})
      })
  }

  handleCarSellPayAddSubmit(event) {
    event.preventDefault();

    let date_pay = event.target.date_pay.value
    let car_sell_list_pay_id = event.target.car_sell_list_pay_id.value
    let car_sell_pay_count = event.target.car_sell_pay_count.value // ยอดใหม่ที่กรอก
    let sales_type_id = event.target.sales_type_id.value
    let bank_id = event.target.bank_id.value
    let person_transaction = this.state.user.user
    let customer_id = this.state.car_sell_pay_add.customer_id
    let car_id = this.state.car_sell_pay_add.car_id

    let data = {
      car_id: car_id,
      customer_id: customer_id,
      date_pay: date_pay,
      car_sell_list_pay_id: car_sell_list_pay_id,
      car_sell_pay_count: car_sell_pay_count,
      sales_type_id: sales_type_id,
      bank_id: bank_id,
      person_transaction: person_transaction,
      user_created: this.state.user.user,
      user_updated: this.state.user.user,
      car_sell_pay_tag: 'จ่ายเพิ่มเติม',
      car_book_id: this.state.car_sell_pay_add.car_book_id
    }

    //add การจ่ายเงินเพิ่มเติม
    axios.post(APIURL + '/car_sell_pay/book/', data)
      .then(res => {

        if (res.status === 200) {
          //load new pay list
          this.setState({
            toggle_add_payment: !this.state.toggle_add_payment
          },()=>{
            this.loadCarBook();
            // this.loadDataCarSellPay()
          });
        }

      })
      .catch(function (error) {
        console.log(error)
        alert('พบข้อผิดพลาด: ' + error)
      })

  }

  handleUploadFile (event){
    const car_book = this.state.car_sell_pay_selected
    const input_file_name = event.target.name
    // let slip_key = input_file_name === 'slip_image1' ? this.state.slip_key1 : this.state.slip_key2
    let slip_key = ''

    const that = this;
    const car_book_id = car_book.car_book_id

    // input_file_name= slip_image2
    if (input_file_name === 'slip_image1'){
      slip_key = this.state.slip_key1 === null ? '' : this.state.slip_key1
      this.refs.img_slip_image1.src='img/loading.gif'
      this.refs.img_slip_image1.height=32
    } else if (input_file_name === 'slip_image2') {
      slip_key = this.state.slip_key2 === null ? '' : this.state.slip_key2
      this.refs.img_slip_image2.src='img/loading.gif'
      this.refs.img_slip_image2.height=32
    }

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('car_book_id', car_book_id)
    data.append('slip_key', slip_key)
    data.append('slip_image', input_file_name)

    axios.put(APIURL+'/car_book/slip/'+car_book_id, data).then(function(res) {
      const resData = res.data;
      const filename = resData.image;
      const slip_key = resData.slip_key;
      let image_url = ''

      if(resData.image === undefined) {
        image_url = IMAGE_UPLOAD_ERROR
      } else if (resData.image === 'uploaderror') {
        image_url = IMAGE_UPLOAD_ERROR
      } else {
        image_url = filename
      }

      if( input_file_name === 'slip_image1'){
        that.setState({
          slip_image1: image_url,
          slip_key1: slip_key
        })
      } else if (input_file_name === 'slip_image2') {
        that.setState({
          slip_image2: image_url,
          slip_key2: slip_key
        })
      }

    })

  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  saveBookNumber() {
     let book_number = this.state.book_number
     let volume_number = this.state.volume_number
     let car_sell_pay = this.state.car_sell_pay_selected
     let car_book_id = car_sell_pay.car_book_id
     let car_sell_pay_id = car_sell_pay.car_sell_pay_id
     let doc_no = car_sell_pay.doc_no

    // include_vat , vat , vat_bath, doc_type , doc_no

    if(car_sell_pay.book_number === null && car_sell_pay.volume_number === null ) {
      // update จาก null
      if (book_number !=='' || volume_number !== '') {
        doc_no = book_number
        this.updateBookNumber(car_book_id, book_number, volume_number, car_sell_pay_id);
      }
    } else if (car_sell_pay.book_number !== book_number || car_sell_pay.volume_number !== volume_number) {
      // มีการ เปลี่ยนค่า
      if (book_number !=='' || volume_number !== '') {
        doc_no = book_number
        this.updateBookNumber(car_book_id, book_number, volume_number, car_sell_pay_id);
      }
    }

    car_sell_pay = {
      ...car_sell_pay,
      book_number: book_number,
      volume_number: volume_number,
      doc_no: doc_no
    }

    this.printOcpb(car_sell_pay)
    this.togglePrintBill()
  }

  updateBookNumber(car_book_id, book_number, volume_number, car_sell_pay_id) {

    let doc_config = this.state.doc_config
    // car_book/book_number

    let data = {
      car_book_id: car_book_id,
      book_number: book_number,
      volume_number: volume_number,
      car_sell_pay_id: car_sell_pay_id,
      doc_config: doc_config
    }

    let api = APIURL+ '/car_book/book_number'
    axios.put(api, data).then(res => {
      this.loadCarBook();
    }).catch(err => {
      console.log('error:', err)
    })
  }

  getCarBookStatusText(car_book_status_id, car_sell_pay_tag, status_field, car_sell_pay) {
      let book_status = ''
      let badge = ''

      if (car_book_status_id === 1 && car_sell_pay_tag===null) {
        book_status = 'จอง'
        badge = 'badge badge-success'
      } else if (car_book_status_id === 1 && car_sell_pay_tag!==null) {
        book_status = car_sell_pay_tag
        badge = 'badge badge-primary'
      } else if (car_book_status_id === 2 && status_field === 'show'){
        if(car_sell_pay.car_sell_pay_tag === 'จ่ายเพิ่มเติม' && car_sell_pay.car_sell_pay_refund === 0){
          book_status = car_sell_pay_tag
          badge = 'badge badge-primary'
        }else{
          book_status = 'คืนเงินจอง'
          badge = 'badge badge-warning'
        }
      } else if (car_book_status_id === 2 && status_field === 'delete'){
        book_status = 'ยกเลิกการจอง'
        badge = 'badge badge-danger'
      }

      return <span className={badge}> {book_status}</span>

  }

  getCarBookManage(car_book_status_id, car_sell_pay_tag, status_field, car_sell_pay) {
    let book_status = ''
    let user_type = this.state.user.type
    if (PORT === 9043 && user_type ==='sale') {
      return <td className='text-center'>
        <i className="icon-close"/>
      </td>
    }

    if (PORT === 9056 && (user_type ==='sale' || user_type ==='stock')) {
      return <td className='text-center'>
        <i className="icon-close"/>
      </td>
    }

    if (car_book_status_id === 1 && car_sell_pay_tag===null) {
      book_status = 'จอง'
      return <td className='text-center'>

        <button onClick={() => {
          // TODO:: แนบสลิป
          this.setState({
            car_sell_pay_selected: car_sell_pay,
            slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
            slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
            slip_key1: car_sell_pay.slip_key1,
            slip_key2: car_sell_pay.slip_key2,
          },() => {
            this.toggleSlip()
          })
        }}
                type="button" className="btn btn-success btn-sm btn-block">
          <i className="icon-wallet">&nbsp;แนบสลิป</i>
        </button>

        {
          checkGroupPermission(701, this.state.grouppermissions).modified ? (
              <button
                  type="button"
                  className="btn btn-info btn-sm btn-block"
                  onClick={() => this.addPayment(car_sell_pay)}
              >
                <i className="icon-plus">&nbsp;เพิ่มการชำระเงิน</i>
              </button>
          ) : null
        }

        {/*{
          port === 9021 && this.state.user.type === 'sale' ? null : (
            <button
              onClick={() => {
                this.setState({
                  book_status: 'edit',
                  car_sell_pay_selected: car_sell_pay
                },() => {
                  this.toggleCarBookPrimary();
                });
              }}
              type="button"
              className="btn btn-warning btn-sm btn-block"
            >
              <i className="icon-pencil">&nbsp;แก้ไขการจอง</i>
            </button>
          )
        }*/}

        {this.getManageCarBookEdit(car_sell_pay)}

        <button onClick={() => this.openCarBookDateModal(car_sell_pay)}
                type="button"
                className="btn btn-sm btn-danger btn-block">
          <i className="icon-calendar"/>&nbsp; แก้ไขวันที่จอง
        </button>

        {
          port === 9043 ? (
            <>
              <button type="button"
                      className="btn btn-sm btn-success  btn-block"
                      onClick={() => this.handleOnClick('car-sales-contract-at', car_sell_pay)}
              >
                <i className="icon-docs">&nbsp; สัญญาขายรถ </i>
              </button>
            </>
          ) : (
            <>
              <button onClick={() => this.printCarBookCustomer(car_sell_pay)}
                      type="button" className="btn btn-success btn-sm btn-block">
                <i className="icon-printer">&nbsp;ใบจองลูกค้า</i>
              </button>

              <button onClick={() => this.printCarBook(car_sell_pay)}
                      type="button" className="btn btn-primary btn-sm btn-block">
                <i className="icon-printer">&nbsp;ใบจองเต็นท์</i>
              </button>
            </>
          )
        }


        <button
          type="button"
          className="btn btn-success btn-sm btn-block"
          onClick={() => {
          // TODO:: กรอกเลขใบเสร็จก่อนพิมพ์
          let doc_config = this.state.doc_config
          if (doc_config === 'manual') {
            let book_number = car_sell_pay.book_number === null ? '' : car_sell_pay.book_number
            let volume_number = car_sell_pay.volume_number === null ? '' : car_sell_pay.volume_number
            this.setState({
              car_sell_pay_selected: car_sell_pay,
              book_number: book_number,
              volume_number: volume_number
            },() => {
              this.togglePrintBill()
            })
          } else {
            this.printOcpb(car_sell_pay);
          }
        }}
        >
          <i className="icon-printer">&nbsp;พิมพ์ใบเสร็จ</i>
        </button>

        {
          port === 9021 && this.state.user.type === 'sale' || port === 9025 && this.state.user.type === 'sale' || port === 9053 && this.state.user.type === 'sale' ? null : (
            <>

              {
                checkGroupPermission(701, this.state.grouppermissions).modified ? (
                    <button onClick={() => {
                      this.refundCarBook(car_sell_pay)
                    }}
                            type="button" className="btn btn-warning btn-sm btn-block">
                      <i className="icon-wallet">&nbsp;คืนเงินจอง</i>
                    </button>
                ) : null
              }

              {/*{PORT === 9046 ?
                  <button onClick={() => {
                    this.deleteCarBookWithDeleteCustomer(car_sell_pay)
                  }}
                          type="button" className="btn btn-danger btn-sm btn-block">
                    <i className="icon-close">&nbsp;ลบการจอง</i>
                  </button>

                  :

                  <button onClick={() => {
                    this.deleteCarBook(car_sell_pay)
                  }}
                          type="button" className="btn btn-danger btn-sm btn-block">
                    <i className="icon-close">&nbsp;ลบการจอง</i>
                  </button>
              }*/}

              {this.getManageCarBookDelete(car_sell_pay)}

            </>
          )
        }

      </td>
    } else if (car_book_status_id === 1 && car_sell_pay_tag!==null) {
      book_status = car_sell_pay_tag
      return <td className='text-center'>

        <button onClick={() => {
          //TODO:: กรอกเลขใบเสร็จก่อนพิมพ์
          // this.setState({car_sell_pay_selected: car_sell_pay},() => {
          // this.togglePrintBill()
          // })
          this.printOcpb(car_sell_pay);
        }}
                type="button" className="btn btn-success btn-sm btn-block">
          <i className="icon-printer">&nbsp;พิมพ์ใบเสร็จ</i>
        </button>

        <button onClick={() => {
          this.deleteCarBookPayAddition(car_sell_pay)
        }}
                type="button" className="btn btn-danger btn-sm btn-block">
          <i className="icon-trash">&nbsp;ลบรายการ</i>
        </button>

      </td>
    } else if (car_book_status_id === 2 && status_field === 'show'){

      if(car_sell_pay_tag==='จ่ายเพิ่มเติม' && car_sell_pay.car_sell_pay_refund === 0) {
        book_status = 'จ่ายเพิ่มเติม'
        return <td className='text-center'>
          <button onClick={() => {
            // TODO:: แนบสลิป
            this.setState({
              car_sell_pay_selected: car_sell_pay,
              slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
              slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
              slip_key1: car_sell_pay.slip_key1,
              slip_key2: car_sell_pay.slip_key2,
            },() => {
              this.toggleSlip()
            })
          }}
                  type="button" className="btn btn-success btn-sm btn-block">
            <i className="icon-wallet">&nbsp;แนบสลิป</i>
          </button>

          <button onClick={() => {
            this.refundCarBook(car_sell_pay)
          }}
                  type="button" className="btn btn-warning btn-sm btn-block">
            <i className="icon-wallet">&nbsp;คืนเงินจอง</i>
          </button>

        </td>
      }else{
        book_status = 'คืนเงินจอง'
        if(port === 9040 && car_book_status_id === 2){
          return <td className='text-center'>
                <button onClick={() => {
                  // TODO:: แนบสลิป
                  this.setState({
                    car_sell_pay_selected: car_sell_pay,
                    slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
                    slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
                    slip_key1: car_sell_pay.slip_key1,
                    slip_key2: car_sell_pay.slip_key2,
                  },() => {
                    this.toggleSlip()
                  })
                }}
                        type="button" className="btn btn-success btn-sm btn-block">
                  <i className="icon-wallet">&nbsp;แนบสลิป</i>
                </button>

              <button onClick={() => {
                // TODO:: กรอกเลขใบเสร็จก่อนพิมพ์
                let book_number = car_sell_pay.book_number === null ? '' : car_sell_pay.book_number
                let volume_number = car_sell_pay.volume_number === null ? '' : car_sell_pay.volume_number
                this.setState({
                  car_sell_pay_selected: car_sell_pay,
                  book_number: book_number,
                  volume_number: volume_number
                },() => {
                  this.togglePrintBill()
                })

                //
                // this.printOcpb(car_sell_pay);
              }}
                      type="button" className="btn btn-success btn-sm btn-block">
                <i className="icon-printer">&nbsp;พิมพ์ใบเสร็จ</i>
              </button>
              </td>

        } else {
          return <td className='text-center'>
            <button onClick={() => {
              // TODO:: แนบสลิป
              this.setState({
                car_sell_pay_selected: car_sell_pay,
                slip_image1: car_sell_pay.slip_image1 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image1,
                slip_image2: car_sell_pay.slip_image2 === null ? IMAGE_NOPHOTO_URL: car_sell_pay.slip_image2,
                slip_key1: car_sell_pay.slip_key1,
                slip_key2: car_sell_pay.slip_key2,
              },() => {
                this.toggleSlip()
              })
            }}
                    type="button" className="btn btn-success btn-sm btn-block">
              <i className="icon-wallet">&nbsp;แนบสลิป</i>
            </button>
          </td>
        }
      }


    } else if (car_book_status_id === 2 && status_field === 'delete'){
      book_status = 'ยกเลิกการจอง'
      return <td className='text-center'>
        <i className="icon-close"/>
      </td>
    }


  }

  handleOnClick(action, car_sell_pay) {
    // let search = this.toQueryString(car_sell_pay)
    //  car_id , customer_id
    let contractCheck = {
      car_book_id : car_sell_pay.car_book_id,
      car_id : car_sell_pay.car_id,
      customer_id : car_sell_pay.customer_id,
    }

    this.setState({
      'btnClick': action,
      'contractCheck': contractCheck
      // 'carsellSelect': search,
      // 'car_sell_id': car_sell.car_sell_id,
      // 'car_sell_detail' : car_sell,
    })
  }

  openCarBookDateModal(car_book) {
    this.setState({
      is_car_book_date_modal: true,
      car_book_id: car_book.car_book_id,
      car_book_date: car_book.car_book_date,
      startDateCarBook: new Date(car_book.car_book_date)
    })
  }

  toggleCarBookDateModal() {
    this.setState({
      is_car_book_date_modal: !this.state.is_car_book_date_modal
    })
  }

  handleCarBookDate(date) {
    // alert (date);
    // console.log('date=', date)
    this.setState({
      startDateCarBook: date,
      car_book_date : date
    });
  }

  editCarBookDate() {
    let car_book_id =  this.state.car_book_id
    let car_book_date =  this.state.car_book_date
    //let page =  this.state.page
    console.log('car_book_date=', car_book_date)
    //console.log('page=', page)

    let car_book_date_db = moment(car_book_date).format('YYYY-MM-DD')

    Swal.fire({
      title: 'ต้องการเปลี่ยนวันที่จองรถเป็น '+ car_book_date_db + ' ใช่หรือไม่',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {

        let car_book_time = moment(new Date()).format('HH:mm:ss')
        let car_book_date = car_book_date_db + ' ' + car_book_time

        let data = {
          car_book_id: car_book_id,
          car_book_date: car_book_date
        }

        // console.log('data===', data)

        axios.put(APIURL+'/car_book/chang_book_date/'+ car_book_id, data)
            .then(res => {
              if(res.status === 200) {
                AlertSuccess('แก้ไขวันที่จองรถเรียบร้อยแล้ว');
                this.loadCarBook()
                this.toggleCarBookDateModal();
              }
            })
            .catch(function(error){
              console.log('error:' + error.response);
              // alert("error:"+error.response);
            });
      }
    })
  }

  render () {

    let car = this.state.car
    let width = this.state.width

    if (this.state.btnClick === 'car-sales-contract-at') {
      let contractCheck = this.state.contractCheck
      let params = 'car_id='+contractCheck.car_id
      params += '&customer_id='+contractCheck.customer_id
      params += '&car_book_id='+contractCheck.car_book_id

      const target = '/car-sales-contract-at/?' + params;

      return <Redirect push to={target} target={"_blank"} />;
    }

    let car_license_plate = car.car_license_plate_new !== '' && car.car_license_plate_new !== car.car_license_plate_old ?
        car.car_license_plate_new : car.car_license_plate_old

    let province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
        car.province_name_new : car.province_name_old


    let car_sell_pay_tr = this.state.car_book_list.map((car_sell_pay, index) => {

      let car_book_status_id = car_sell_pay.car_book_status_id
      let car_sell_pay_refund = car_sell_pay.car_sell_pay_refund
      let car_sell_pay_tag = car_sell_pay.car_sell_pay_tag
      let car_book_id = car_sell_pay.car_book_id
      let book_number = car_sell_pay.book_number === '' ? 'R'+car_book_id : car_sell_pay.book_number
      let volume_number = car_sell_pay.volume_number
      let book_comment_refund = car_sell_pay.book_comment_refund === '' || car_sell_pay.book_comment_refund === null ? '-' : car_sell_pay.book_comment_refund
      let employee_book = car_sell_pay.employee_book_id === 0 || car_sell_pay.employee_book_id === '' ? 'ไม่ระบุ' : car_sell_pay.employee_book_name + ' ' + car_sell_pay.employee_book_lastname

      return (
        <tr key={index}>
          <td className="text-left text-nowrap"
          >

            {
              book_number === null ? <span className="text-danger">เลขการจอง: {car_book_id}</span> : (
                <>
                <span className="text-danger">เล่มที่ : {volume_number}</span> <br/>
                <span className="text-success">เลขที่ใบจอง : {book_number}</span>
                </>
              )
            }
            <br/>
            วันที่ทำการจอง : {moment(car_sell_pay.car_book_date).locale('th').format('LL')}
            <br/>
            วันที่ชำระเงินจอง : {moment(car_sell_pay.date_pay).locale('th').format('LL')}
          </td>
          <td className="text-center">
            {car_sell_pay.customer_name}&nbsp;&nbsp;{car_sell_pay.customer_lastname}<br/>
            {/*<span
              className={car_sell_pay.car_book_status_id === 1 ?  car_sell_pay_tag===null ?
                'badge badge-success' : 'badge badge-primary' : 'badge badge-danger' }
            >
                {car_sell_pay.car_book_status_id === 1 ? (car_sell_pay_tag===null ? 'จอง' : car_sell_pay_tag) : ' ยกเลิกการจอง' }

            </span>*/}
            {this.getCarBookStatusText(car_sell_pay.car_book_status_id, car_sell_pay_tag, car_sell_pay.status_field,car_sell_pay )}

            {
              car_sell_pay.book_comment === null ? null : (
                <>
                  <hr/>
                  หมายเหตุการจอง :
                  <br/>
                </>
              )
            }
            {
              car_sell_pay.book_comment === null ? null : parse(car_sell_pay.book_comment)
            }

            <hr/>
            {
              PORT === 9000 || PORT === 9008 || PORT === 9048 || PORT >= 9056 ?
                          <>
                            ผู้ขาย : {employee_book}
                          </>

                  :
                      car_sell_pay.booking_by === null ? null : (
                        <>
                          ผู้รับจอง : {car_sell_pay.booking_by === null || car_sell_pay.booking_by === '' ? 'ไม่ระบุ' : car_sell_pay.booking_by}
                        </>
              )
            }



          </td>
          <td className="text-center text-nowrap">
            {car_sell_pay.car_sell_list_pay_name}

            {
              car_sell_pay.slip_image1 === null ? null : (
                <>
                  <br/>
                  <br/>
                  <label htmlFor="name">
                  <a rel="noopener noreferrer"
                     href={car_sell_pay.slip_image1}
                     target="_blank"
                     onClick={(e)=>this.saveCarImage(e, car_sell_pay.slip_image1)}
                     className={car_sell_pay.slip_image1===null? 'hidden' : ''}>
                    <span>Download</span>
                  </a>
                  </label>
                  <br/>
                <img
                  src={car_sell_pay.slip_image1}
                  style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                  onClick={()=>this.onPreviewSelected(car_sell_pay.slip_image1)}
                  alt="เอกสาร 1"
                />
                </>
              )
            }

          </td>
          <td className="text-center text-nowrap">
            <NumberFormat value={car_sell_pay.car_sell_pay_count}
                          displayType={'text'}
                          thousandSeparator={true} prefix={'฿'}
            />

            {/*{car_book_status_id === 1 && car_sell_pay_tag===null ? '/คืนเงิน ': '' }*/}

            {/*{car_book_status_id === 1 && car_sell_pay_tag===null ?  <NumberFormat value={car_sell_pay_refund} displayType={'text'} thousandSeparator={true} prefix={'฿'}/> : '' }*/}

            {
              car_sell_pay_refund !== 0 ? '/คืนเงิน ': null
            }
            {
              car_sell_pay_refund !== 0 ? <NumberFormat value={car_sell_pay_refund} displayType={'text'} thousandSeparator={true} prefix={'฿'}/> : null
            }

            <hr/>
            {
              car_sell_pay_refund !== 0 ? 'หมายเหตุคืนเงินจอง : ' + book_comment_refund : null
            }

            {
              car_sell_pay.slip_image2 === null ? null : (
                <>
                  <br/>
                  <br/>
                  <label htmlFor="name">
                    <a rel="noopener noreferrer"
                       href={car_sell_pay.slip_image2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e, car_sell_pay.slip_image2)}
                       className={car_sell_pay.slip_image2===null? 'hidden' : ''}>
                      <span>Download</span>
                    </a>
                  </label>
                  <br/>
                  <img
                    src={car_sell_pay.slip_image2}
                    style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                    onClick={()=>this.onPreviewSelected(car_sell_pay.slip_image2)}
                    alt="เอกสาร 2"
                  />
                </>
              )
            }

          </td>
          <td className="text-center text-nowrap">{car_sell_pay.sales_type_name}</td>
          <td className="text-center text-nowrap">
            {car_sell_pay.bank_name}
          </td>
          <td className="text-center text-nowrap">
            {/*{car_sell_pay.person_transaction}*/}
            {car_sell_pay.book_by} <br/>
            {/*เมื่อ : {moment(car_sell_pay.book_date).locale('th').format('LL')}*/}
            แก้ไขล่าสุด : {moment(car_sell_pay.car_book_update).locale('th').format('LL HH:mm:ss')}
          </td>

          {this.getCarBookManage(car_sell_pay.car_book_status_id, car_sell_pay_tag, car_sell_pay.status_field, car_sell_pay)}


        </tr>
      )
    });

    let car_sell_list_pay_list = this.state.car_sell_list_pay.map((car_sell_list_pay, index) => {
      return (
        <option key={index}
                value={car_sell_list_pay.car_sell_list_pay_id}
        >
          {car_sell_list_pay.car_sell_list_pay_name}
        </option>
      )
    });

    let sales_type_list = this.state.sales_type.map((sales_type, index) => {
      return (
        <option key={index} value={sales_type.sales_type_id}>{sales_type.sales_type_name}</option>
      )
    });

    let bank_pay_list = this.state.bank.map((bank, index) => {
      return (
        <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
      )
    });

    return (

      <div className="row card"  style={styles.overflowY}>
        <Modal isOpen={this.state.refund} toggle={this.toggleRefund.bind(this)}
               className={'modal-lg modal-danger'}>
          <ModalHeader toggle={this.toggleRefund.bind(this)}>คืนเงินจอง</ModalHeader>
          <ModalBody>
            <div className="card-block">
              <div className="row">
                <div className="row p-5">
                  <div className="form-group col-sm-4">
                    <strong>ยอดเงินจองที่ชำระ :</strong>
                  </div>
                  <div className="form-group col-sm-8">
                    <input type="text"
                           className="form-control hidden"
                           ref="car_sell_pay_count"
                           value={this.state.car_sell_pay_count}
                           onChange={() => {}}
                    />

                    <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={true}
                        value={this.state.car_sell_pay_count}
                        displayType="text"
                        //onValueChange={() => {}}
                    />
                  </div>

                  <div className="form-group col-sm-4">
                    <strong>ยอดคืนเงิน :</strong>
                  </div>

                  <div className="form-group col-sm-8">
                    <input type="text"
                           className="form-control hidden"
                           name="car_book_refund"
                           placeholder=""
                           ref="car_book_refund"
                           value={this.state.car_book_refund}
                           onChange={() => {}}
                    />
                      <NumberFormat
                        className="form-control"
                        thousandSeparator={true}
                        prefix={'฿'}
                        placeholder="คืนเงิน"
                        min={0}
                        max={1000000000}
                        step={1000}
                        size={10}
                        allowNegative={false}
                        onValueChange={(values) => {
                          const {value} = values;
                          this.setState({car_book_refund: value});
                        }}
                      />
                  </div>

                  <div className="form-group col-sm-4">
                    <strong>หมายเหตุการคืนเงินจอง :</strong>
                  </div>

                  <div className="form-group col-sm-8">
                    <input type="text"
                           className="form-control"
                           id="book_comment_refund"
                           name="book_comment_refund"
                           //value={this.state.book_comment_refund || ''}
                           onChange={(e)=>{
                             this.setState({
                               book_comment_refund: e.target.value
                             })
                           }}
                    />
                  </div>

                </div>
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleRefund.bind(this)}>ยกเลิก</Button>
            <Button color="primary"
                    onClick={this.carBookCancelRefund.bind(this)}>ตกลง</Button>{' '}

          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.danger} toggle={this.toggleDanger.bind(this)}
               className={'modal-danger'}>
          <ModalHeader toggle={this.toggleDanger.bind(this)}>ลบการจอง</ModalHeader>
          <ModalBody>
            {PORT === 9046 ? <strong> คุณต้องการลบการจองและลบข้อมูลลูกค้า {this.state.car_sell_pay_selected.customer_name} ใช่หรือไม่ ?? </strong>
                :
                <strong> คุณต้องการลบการจอง {this.state.car_sell_pay_selected.customer_name} ใช่หรือไม่ ?? </strong>
            }
            <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleDanger.bind(this)}>ยกเลิก</Button>
            {PORT === 9046 ? <Button color="primary"
                                     onClick={()=>{
                                       this.carBookCancelWithDeleteCustomer(this.state.car_book_id)
                                     }}>ตกลง</Button>
                                     :
                                      <Button color="primary"
                                       onClick={()=>{
                                         this.carBookCancel(this.state.car_book_id)
                                       }}>ตกลง</Button>
            }

          </ModalFooter>
        </Modal>

        <Modal className="modal-lg modal-dialog" isOpen={this.state.toggle_bill_print} toggle={this.togglePrintBill}>
          <ModalBody>
            <div id="divToPrint"  >
              <div className="row">
                <div className="col-md-12 text-center">
                  <strong>เพิ่มข้อมูลใบจอง</strong>
                </div>
              </div>

              <div className="row pt-5">

                <div className="form-group col-sm-2">
                </div>
                <div className="form-group col-sm-3">
                  <strong>เล่มที่ :</strong>
                </div>
                <div className="form-group col-sm-6">
                  <input type="text"
                         name="volume_number"
                         maxLength={10}
                         value={this.state.volume_number}
                         onChange={(e) => {
                           this.setState({
                             volume_number: e.target.value
                           })
                         }}
                  />
                </div>
              </div>

              <div className="row">

                <div className="form-group col-sm-2">
                </div>
                <div className="form-group col-sm-3">
                  <strong>เลขที่ใบเสร็จ :</strong>
                </div>
                <div className="form-group col-sm-6">
                  <input type="text"
                         name="book_number"
                         maxLength={20}
                         value={this.state.book_number}
                         onChange={(e) => {
                           this.setState({
                             book_number: e.target.value
                           })
                         }}
                  />
                </div>
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={()=> this.saveBookNumber()} >พิมพ์ใบเสร็จ</Button>{' '}
            <Button color="secondary" onClick={this.togglePrintBill}>ยกเลิก</Button>
          </ModalFooter>
        </Modal>

        <Modal className="modal-lg modal-dialog" isOpen={this.state.toggle_slip} toggle={this.toggleSlip}>
          <ModalHeader toggle={this.toggleSlip.bind(this)}>แนบสลิป</ModalHeader>
          <ModalBody>
              <div className="row p-5">
                <div className="form-group col-sm-1">
                </div>

                <div className="form-group col-sm-5">
                  <label htmlFor="name">1) เอกสาร 1
                    &nbsp;
                    <a rel="noopener noreferrer"
                       href={this.state.slip_image1}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.slip_image1)}
                       className={this.state.slip_image1===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>Download</span>
                    </a>
                  </label>
                  <div style={styles.fileInputWrapper} >

                    <button style={styles.btnFileInput}>
                      <img src={this.state.slip_image1}
                           style={styles.btnFileInputImage}
                           ref="img_slip_image1"
                           alt="เอกสาร 1"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="slip_image1"
                      onChange={this.handleUploadFile}
                    />
                  </div>

                </div>

                <div className="form-group col-sm-5">
                  <label htmlFor="name">2) เอกสาร 2
                    &nbsp;
                    <a rel="noopener noreferrer"
                       href={this.state.slip_image2}
                       target="_blank"
                       onClick={(e)=>this.saveCarImage(e,this.state.slip_image2)}
                       className={this.state.slip_image2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                      <span>Download</span>
                    </a>
                  </label>
                  <div style={styles.fileInputWrapper} >
                    <button style={styles.btnFileInput}>
                      <img src={this.state.slip_image2}
                           style={styles.btnFileInputImage}
                           ref="img_slip_image2"
                           alt="เอกสาร 2"
                      />
                    </button>
                    <input
                      style={styles.fileInput}
                      type="file"
                      name="slip_image2"
                      onChange={this.handleUploadFile}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-1">
                </div>
              </div>
          </ModalBody>
          <ModalFooter>
            {/*<Button color="primary" onClick={()=>{
            }}>
               xxx
            </Button>*/}
            {' '}
            <Button color="secondary" onClick={() => {
              // TODO: reload car_book
              this.loadCarBook();
              this.toggleSlip();

            }}>ยกเลิก</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.is_car_book_date_modal} toggle={this.toggleCarBookDateModal}
               className={'none modal-dialog'}>
          <ModalBody>

            <div className="card card-accent-primary">

              <div className="card-header">
                <strong className="text-title">แก้ไขวันที่จองรถ</strong>
              </div>

              <div className="card-block">
                <div className="row">
                  <div className="col-lg-12">

                    <div className="form-group col-sm-12">
                      <label htmlFor="car_book_date"> วันที่จองรถ </label>
                      <DatePicker
                          selected={this.state.startDateCarBook}
                          onChange={this.handleCarBookDate}
                          dateFormat="yyyy-MM-dd"
                          type="text"
                          className="form-control ml-4"
                          id="car_book_date"
                          name="car_book_date"
                          ref="car_book_date"
                          value={this.state.car_book_date}
                          placeholder=""
                      />
                    </div>

                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <button type="button"
                        className="btn btn-sm btn-danger mr-2"
                        onClick={() => this.toggleCarBookDateModal()}
                        style={{width: 80}}
                >
                  <i className="fa fa-refresh"/> ยกเลิก
                </button>

                <button type="button"
                        className={"btn btn-sm btn-primary"}
                        style={{width: 80}}
                        onClick={() => this.editCarBookDate()}
                >
                  <i className={"fa fa-save"}/> บันทึก
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <div className="card-block">
          {/*<form action="" method="post" onSubmit={this.handleOnSubmitCarBook}>*/}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-title">เพิ่มรายละเอียดการจองรถ</strong>
                  </div>

                  <div className="card-block">
                    <div className="row">
                      <div className="col-12">
                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-2 ">
                            <strong> ชื่อรถ </strong></label>
                          <div className="col-md-10">
                            <p className="text-justify text-primary">
                              <strong> {car.car_name} </strong>
                            </p>
                          </div>
                        </div>

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-2"><strong> ยี่ห้อ </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car.car_brand_name} </strong></p>
                          </div>

                          <label className="col-md-2 "><strong> รุ่นรถ </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car.car_model_name} </strong></p>
                          </div>

                          <label className="col-md-2"><strong> รุ่นย่อย </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car.car_sub_model_name} </strong></p>
                          </div>
                        </div>

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-2"><strong> ปีรถ </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car.car_year_name} </strong></p>
                          </div>

                          <label className="col-md-2"><strong> ระบบเกียร์ </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car.car_gear_name} </strong></p>
                          </div>

                          <label className="col-md-2"><strong> สีรถ </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car.car_color_name} </strong></p>
                          </div>
                        </div>

                        <div className=" form-group row" style={styles.bottomLine}>
                          <label className="col-md-2"><strong> เลขไมล์ </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car.car_miles} </strong></p>
                          </div>

                          <label className="col-md-2"><strong> ป้ายทะเบียนรถ </strong></label>

                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {car_license_plate} </strong>
                            </p>
                          </div>

                          <label className="col-md-2"><strong> จังหวัด </strong></label>
                          <div className="col-md-2">
                            <p className="text-justify text-primary">
                              <strong> {province_name} </strong></p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">

                    <strong className="text-title">ข้อมูลการจอง</strong>

                    {
                      checkGroupPermission(701, this.state.grouppermissions).created ? (
                          <button type="button" onClick={()=>{
                            this.setState({
                              book_status: 'add'
                            },() => this.toggleCarBookPrimary())
                          }}
                                  className="btn btn-info float-right">
                            <i className="icon-plus"/>&nbsp;เพิ่มการจองรถ
                          </button>
                      ) : null
                    }


                  </div>

                  <div className="card-block">
                    <div className="row">
                      <div className="col-lg-12">
                        <table className="table table-bordered table-striped table-md">
                          <thead>
                          <tr>
                            <th className="text-center text-nowrap" width="16%">วันที่</th>
                            <th className="text-center text-nowrap" width="20%">ผู้จอง</th>
                            <th className="text-center text-nowrap" width="15%">รายการ</th>
                            <th className="text-center text-nowrap" width="15%">ยอดเงินที่ชำระ</th>
                            <th className="text-center text-nowrap" width="17%">ประเภทการชำระ</th>
                            <th className="text-center text-nowrap" width="18%">ธนาคาร</th>
                            <th className="text-center text-nowrap" width="21%">ผู้บันทึกรายการ</th>
                            <th className="text-center text-nowrap" width="13%">จัดการ</th>
                          </tr>
                          </thead>
                          <tbody>
                          {car_sell_pay_tr}
                          <tr
                            className={(this.state.car_book_list.length === 0) ? 'text-center' : ' hidden'}>
                            <td colSpan="8">
                              <span className="text-danger"> ไม่พบข้อมูลการจอง </span>
                            </td>
                          </tr>

                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          {/*</form>*/}

          <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
            <ModalBody>
              <PreviewImage
                imageUrl={this.state.preview_image_url}
              />
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.carbookprimary}
                 toggle={this.toggleCarBookPrimary}
                 className="modal-book-lg"
                 backdrop={'static'}
                 style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
          >
            <ModalBody>
              <CarBookForm
                car_id={this.state.car_id}
                car={car}
                onLoadCarBook={this.loadCarBook}
                onToggle={this.toggleCarBookPrimary}
                //for edit
                book_status={this.state.book_status}
                car_sell_pay_selected={this.state.car_sell_pay_selected}
              />
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.toggle_add_payment}
            toggle={this.toggleAddPayment}
            className="modal-lg"
            backdrop={'static'}
          >
            <ModalBody>
              <form
                action="" method="post"
                onSubmit={ this.handleCarSellPayAddSubmit }
              >

                <div className="card card-accent-warning">
                  <div className="card-header card-sparepart">
                    <strong className="text-title">เพิ่มการชำระเงิน</strong>
                  </div>
                  <div className="card-block">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="date_pay">วันที่ชำระเงิน </label>
                        <br />
                        <DatePicker
                          selected={this.state.date_pay}
                          onChange={this.handleDatePayAdd}
                          dateFormat="yyyy-MM-dd"
                          type="text"
                          className="form-control"
                          id="date_pay"
                          name="date_pay"
                          placeholder="วันที่ชำระเงิน"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="car_repair_complete">รายการ </label>
                        <select
                          className="form-control"
                          id="car_sell_list_pay_id"
                          ref="car_sell_list_pay_id"
                          value={this.state.car_sell_list_pay_id}
                          onChange={(e)=>{
                            const { target } = e
                            this.setState({
                              car_sell_list_pay_id: target.value
                            });
                          }}
                        >
                          <option value=''>เลือกรายการ</option>
                          {car_sell_list_pay_list}
                        </select>

                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="car_sell_pay_count">จำนวนเงินที่ชำระ </label>
                        <input type="text"
                               className="form-control hidden"
                               id="car_sell_pay_count"
                               name="car_sell_pay_count"
                               placeholder=""
                               ref="car_sell_pay_count"
                               value={this.state.car_sell_pay_count}
                               onChange={()=>{}}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.car_sell_pay_count}
                          onValueChange={(values) => {
                            const {value} = values
                            this.setState({car_sell_pay_count: value}, () => {
                            })
                          }}
                        />

                        <input type="text hidden"
                               className="form-control hidden"
                               id="car_sell_pay_count_old"
                               name="car_sell_pay_count_old"
                               placeholder=""
                               ref="car_sell_pay_count_old"
                               value={this.state.car_sell_pay_count_old}
                               onChange={()=>{}}
                        />

                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="car_sell_pay_count">ประเภทการชำระ </label>
                        <select
                          className="form-control"
                          id="sales_type_id"
                          ref="sales_type_id"
                          value={this.state.sales_type_id}
                          onChange={this.carSalesPayTypeChange.bind(this)}
                        >
                          <option value=''>เลือกประเภทการรับชำระ</option>
                          {sales_type_list}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="bank_id">ธนาคาร </label>
                        <select
                          className="form-control"
                          id="bank_id"
                          name="bank_id"
                          ref="bank_id"
                          value={this.state.bank_id}
                          onChange={(e)=>{
                            const { target } = e
                            this.setState({
                              bank_id: target.value
                            });
                          }}
                        >
                          <option value=''>เลือกธนาคาร</option>
                          {bank_pay_list}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer text-right">
                    <button type="reset"
                            onClick={()=>{
                              this.setState({
                                toggle_add_payment: false
                              });
                            }}
                            className="btn btn-sm btn-danger mr-2">
                      <i className="fa fa-refresh"/> ยกเลิก
                    </button>

                    <button type="submit"
                            className="btn btn-sm btn-primary">
                      <i className="fa fa-plus"/> เพิ่มชำระเงิน
                    </button>

                    <input type="hidden" id="car_sell_pay_id" name="car_sell_pay_id" value={this.state.car_sell_pay_id}/>

                  </div>
                </div>

              </form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    )
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  type: state.auth.type,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarBook)
